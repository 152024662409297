import React, { useCallback, useEffect, useRef, useState } from 'react'
import Janus, { publishToRoom, subscribeRemoteFeed } from 'janus-js-core'
import PropTypes from 'prop-types'
import GroupLiveStreamModal from './GroupLiveStreamModal'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setGroupStreamData, setStreamJoined, startLiveStream, resetLiveStreamState } from '../../actions/streamAction'
import LiveStreamModal from './LiveStreamModal'
import ConfirmSweetAlert from '../Layouts/ConfirmSweetAlert'
import { removeConfirmSweetAlert, setConfirmSweetAlert } from '../../actions/sweetAlertActions'

const StreamSubscriber = (props) => {

    const $video = useRef(null) // ref to video element for media stream
    const [loader, setLoader] = useState(true)
    const [remoteFeed, setRemoteFeed] = useState(null)
    const [showConfirmAlert, setConfirmAlert] = useState(false)

    const handleRemoteFeedCallback = useCallback((_remoteFeed, eventType, data) => {

        setRemoteFeed(_remoteFeed)
        if (eventType === 'onremotestream' && $video.current) {
            Janus.attachMediaStream($video.current, data)

        } else if (eventType === 'oncleanup') {
            // use in production mode, will cause problem when publisher and viewer in one page like in demo
            // state.janusInstance.destroy()
        } else if (eventType === 'error') {
            alert('Error while join stream')
        }
    }, [])

    const handlePublishToRoomCallback = useCallback(
        (_sfutest, eventType, data) => {
            switch (eventType) {
                case 'joined':
                case 'publishers': {
                    if (data.publishers) {
                        const list = data.publishers
                        if (list.length === 0) {
                            return
                        }

                        const publisher = list[0]
                        const { id, display, audio_codec, video_codec } = publisher
                        subscribeRemoteFeed({
                            janus: props.janusInstance,
                            opaqueId: '',
                            room: props.room,
                            id,
                            pvtId: props.pubPvtId,
                            display,
                            audio: audio_codec,
                            video: video_codec,
                            callback: handleRemoteFeedCallback
                        })
                        setLoader(false)
                    }

                    break
                }
                case 'leaving':
                case 'unpublished': {
                    if (remoteFeed !== null) {
                        remoteFeed.detach()
                    }
                    if (!props.isGroupLive)
                        props.resetLiveStreamState()
                    break
                }
                default:
                    break
            }
        },
        [handleRemoteFeedCallback, props.janusInstance, props.pubPvtId, props.room]
    )

    useEffect(() => {
        if (
            !props.janusInstance ||
            !props.pubId ||
            !props.pubPvtId ||
            !props.room
        ) {
            return
        }
        // connecting to existing videoroom
        publishToRoom({
            janus: props.janusInstance,
            opaqueId: '',
            room: props.room,
            secret: null,
            pin: null,
            username: null,
            isPublisher: false,
            callback: handlePublishToRoomCallback
        })
    }, [
        props.janusInstance,
        props.pubPvtId,
        props.pubId,
        handleRemoteFeedCallback,
        props.room,
        handlePublishToRoomCallback
    ])

    const handleStopClick = () => {
        setConfirmAlert(true)
        props.setConfirmSweetAlert({
            description: 'Are you sure that you want to close the Go Live session?'
        })
    }

    const stopLiveStream = () => {
        props.removeConfirmSweetAlert()
        props.stopShowStream()
        if (props.isGroupLive === true) {
            props.history.push('/')
        }
    }


    return (
        <div className="d-flex" style={{ background: 'black', justifyContent: 'center' }}>
            <div className="flex-item-12 spacing w-100">
                <div className="d-flex flex-column">
                    {props.isGroupLive === true &&
                        <GroupLiveStreamModal
                            video={$video}
                            isPublisher={false}
                            handleStopClick={handleStopClick}
                            loading={loader}
                            isGroupLive={props.isGroupLive}
                        />
                    }
                    {props.isGroupLive !== true &&
                        <LiveStreamModal
                            video={$video}
                            isPublisher={false}
                            handleStopClick={handleStopClick}
                            loading={loader}
                            isGroupLive={props.isGroupLive}
                        />
                    }
                </div>
            </div>

            {showConfirmAlert && <ConfirmSweetAlert onConfirm={() => { props.startLiveStream(false); props.setStreamJoined(false); stopLiveStream(); setConfirmAlert(false) }} onCancel={() => setConfirmAlert(false)} />}
        </div>
    )
}

StreamSubscriber.propTypes = {
    room: PropTypes.number.isRequired,
    pubId: PropTypes.number.isRequired,
    pubPvtId: PropTypes.number.isRequired,
    janusInstance: PropTypes.object.isRequired,
    stopShowStream: PropTypes.func.isRequired,
    isGroupLive: PropTypes.bool,
    setGroupStreamData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    setConfirmSweetAlert: PropTypes.func.isRequired,
    removeConfirmSweetAlert: PropTypes.func.isRequired,
    startLiveStream: PropTypes.func.isRequired,
    setStreamJoined: PropTypes.func.isRequired,
    resetLiveStreamState: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, {
    setStreamJoined,
    setGroupStreamData,
    setConfirmSweetAlert,
    removeConfirmSweetAlert,
    startLiveStream,
    resetLiveStreamState
})(withRouter((StreamSubscriber)))
