
import * as Sentry from '@sentry/react'
import package_data from './../package.json'

const packageVersion = package_data.version

if (window.location.hostname !== 'localhost') {
    Sentry.init({
        dsn: 'https://89beac87958242498064e23197071c67@o392495.ingest.sentry.io/5932093',
        ignoreErrors: [
            'TypeError: ChunkLoadError'
        ],
        integrations: [Sentry.browserTracingIntegration()],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.001,
        release: packageVersion
    })
}
