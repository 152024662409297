import io from 'socket.io-client'
import store from '../store'
import { getAppBaseUrl } from './../actions/api'
import {
    SET_SOCKET_CONNECTED,
    SET_SOCKET_DISCONNECTED
} from '../actions/types'
import _ from 'lodash'

const BASE_URL = getAppBaseUrl()
const socket = io(BASE_URL)

socket.on('connect', () => {
    const state = store.getState()
    const userId = _.get(state, 'auth.user._id', false)
    const isAdmin = _.get(state, 'auth.user.isAdmin', false)
    const role = _.get(state, 'auth.user.role', '')
    if (userId && isAdmin === false && role !== 'proxy_user') {
        socket.emit('USER_ONLINE', { userId: userId })
    }
    console.log('socket: client connected ' + socket.id)
    store.dispatch({ type: SET_SOCKET_CONNECTED })
})

socket.on('disconnect', () => {
    store.dispatch({ type: SET_SOCKET_DISCONNECTED })
    console.log('client disconnected')
})

export default socket
