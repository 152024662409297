// @flow
import './instrument'
import React from 'react'
import { createRoot } from 'react-dom/client'

import './index.css'
import './c3jscustom.css'

import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'

import App from './App.react'
// import * as serviceWorker from './serviceWorker'

// Added for sentry tracing. Add the below code in package.json scripts section to upload sourcemaps
// "build": ". ./sentry.config.sh && react-scripts build && node uploadSourceMaps.js && rm build/static/js/*.map",

const rootElement = document.getElementById('root')

const root = createRoot(rootElement)
root.render(
    <div id='scrollBottom' className='disableSave' onContextMenu={contextMenu}>
        <App />
    </div>
)

// serviceWorker.unregister()

function contextMenu(e) {
    let debug = window.location.hostname === 'localhost' || window.location.hostname === 'modelplatform.themccandlessgroup.com' || window.location.hostname === 'demo1.themccandlessgroup.com' || window.location.hostname === 'demo.themccandlessgroup.com' || window.location.hostname === 'demo2.themccandlessgroup.com' ? true : false
    if (debug) return true
    e.preventDefault()
    // Do something here....
    return false
}
