import React, { useState, useEffect } from 'react'
import PageWrapper from '../Layouts/PageWrapper'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getAllMedia, addCategory, getAllCategory, updateCategory, deleteCategory, updateFilter, addMedia, getMediaOnScroll, removeMedia, updateSelectedCategory } from '../../actions/mediaAction'
import _ from 'lodash'
import { setSweetAlert, setConfirmSweetAlert, setAlertLoader, removeConfirmSweetAlert } from './../../actions/sweetAlertActions'
import styled from 'styled-components'
import MediaCommonContent from './MediaCommonContent'
import ConfirmSweetAlert from '../Layouts/ConfirmSweetAlert'
import { isIOS } from 'react-device-detect'

const PhotoVideoDiv = styled.div`
    font-size: 14px;
    opacity: .5;
    span {
        padding-right: 5px;
        line-height: 0;
    }
    i {
        padding-right: 5px;
    }
    .padding-right {
        padding-right: 10px;
    }
`

function Media(props) {
    const [activeTab, setActiveTab] = useState(props.isPopup ? 'all' : props.media.filter.type)
    const { category, currentPage, totalPages, media } = props.media
    const [showAddCategoryPopup, setShowAddCategoryPopup] = useState(false)
    const { getAllMedia, addCategory, getAllCategory, updateCategory, getMediaOnScroll } = props
    const [isLoading, setIsLoading] = useState(true)
    const [categoryName, setCategoryName] = useState('')
    const [isCategoryLoading, setIsCategoryLoading] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [isDisableCategory, setIsDisableCategory] = useState(false)
    const [addOrUpdateCategory, setAddOrUpdateCategory] = useState('add_category')
    const [categoryId, setCategoryId] = useState('')
    const [oldCategoryName, setOldCategoryName] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [isMediaLoadingOnScroll, setIsMediaIsLoadingOnScroll] = useState(false)
    const [prevPage, setPrevPage] = useState(0)
    const [selectedIds, setSelectedIds] = useState([])
    const [confirmFor, setConfirmFor] = useState('')
    const [init, setInit] = useState(0)
    const [categoryInit, setCategoryInit] = useState(0)
    const [showCategoryPopup, setShowCategoryPopup] = useState(false)
    const [isOpenCategory, setIsOpenCategory] = useState(false)
    const [newCategoryId, setNewCategoryId] = useState('')
    const [isFromMoveCategory, setIsMoveCategory] = useState(false)
    const [isError, setIsError] = useState([])
    const categoryList = category && category.length > 0 && category.find(obj => obj.slug === 'all media')
    const categoryListId = (props.media.filter.activeCategory && !props.isPopup) ? props.media.filter.activeCategory : categoryList ? categoryList._id : ''

    const loadMediaOnScroll = async () => {
        setPrevPage(currentPage)
        setIsMediaIsLoadingOnScroll(true)
        await getMediaOnScroll({ type: activeTab, page: currentPage + 1, getProcessedMedia: (props.isPopup ? true : false) })
        setIsMediaIsLoadingOnScroll(false)
    }

    const handleScroll = (e) => {
        e.preventDefault()
        if ((!isLoading || !isMediaLoadingOnScroll) && (totalPages > currentPage) && (prevPage === currentPage - 1)) {
            let winScroll = 0
            let height = 0
            if (props.isPopup) {
                const el = document.getElementById('media-modal-body')
                winScroll = el.scrollTop || el.scrollTop
                height = el.scrollHeight - el.clientHeight
            } else {
                winScroll = document.body.scrollTop || document.documentElement.scrollTop
                height = document.documentElement.scrollHeight - document.documentElement.clientHeight
            }
            const scrolled = winScroll / height
            if (scrolled > 0.98 || (isIOS === false && scrolled > 0.93)) {
                loadMediaOnScroll()
            }
        }
    }

    const getAllMediaByType = async (type, categoryId = '', mediaLeftForProcessing = false) => {
        props.updateFilter({ type })
        setIsLoading(true)
        const data = {
            type: type,
            categoryId: categoryId ? categoryId : props.media.filter.activeCategory,
            getProcessedMedia: (props.isPopup ? true : false),
            mediaLeftForProcessing: mediaLeftForProcessing
        }
        await getAllMedia(data)
        setIsLoading(false)
    }

    const isMediaLeftForProcessing = () => {
        let isMediaLeftForProcessing = false
        if (props.media.category && props.media.category.length > 0) {
            props.media.category.forEach(item => {
                if ((item.photo_count !== item.processed_photo_count) || (item.video_count !== item.processed_video_count)) {
                    isMediaLeftForProcessing = true
                }
            })
        }
        return isMediaLeftForProcessing
    }

    // get all media
    useEffect(() => {
        let catId = ''
        const { media: loadedMedia, filter } = props.media
        const mediaLeftForProcessing = isMediaLeftForProcessing()
        const isNotDefaultType = props.media.filter.type !== 'all'
        const isNotDefaultCategory = props.media.filter.activeCategory !== categoryListId
        if ((init === 0 && props.isPopup && isNotDefaultCategory)) {
            catId = categoryListId
            props.updateSelectedCategory(categoryListId)
        }

        const loadData = (init === 0 && (loadedMedia.length === 0 || (props.isPopup && (isNotDefaultType || isNotDefaultCategory))))
            || (init !== 0)
            || mediaLeftForProcessing
            || filter.stateHadBeenReset
        if (init === 0) setInit(1)
        if (loadData) {
            getAllMediaByType(activeTab, catId, mediaLeftForProcessing)
        } else {
            setIsLoading(false)
        }
    }, [activeTab])

    useEffect(() => {
        // get all category list
        const getCategory = async () => {
            setIsCategoryLoading(true)
            await getAllCategory()
            setIsCategoryLoading(false)
        }
        const loadData = (categoryInit === 0 && props.media.category.length === 0)
        if (categoryInit === 0) setCategoryInit(1)
        if (loadData) getCategory()
    }, [])

    // add new category
    const addNewCategory = async (e) => {
        e.preventDefault()
        const name = categoryName.trim()
        const isValidName = handleCheckCategoryName(name, 'add_category')
        if (isValidName === false) {
            return
        }

        setIsDisableCategory(true)
        const id = await addCategory({ name: name })
        if (isFromMoveCategory === true) {
            setNewCategoryId(id)
        }
        setIsDisableCategory(false)
        hideCategoryPopup()
    }

    // update category name
    const changeCategoryName = async (e) => {
        e.preventDefault()
        const name = categoryName.trim()
        const isValidName = handleCheckCategoryName(name, 'update_category', oldCategoryName)
        if (isValidName === false) {
            return
        }
        setIsDisableCategory(true)
        const data = {
            name: name,
            id: categoryId
        }
        await updateCategory(data)
        setIsDisableCategory(false)
        hideCategoryPopup()
    }

    // delete category
    const deleteMediaCategory = async () => {
        setIsDisableCategory(true)
        await props.deleteCategory({ categoryId: categoryId })
        setIsDisableCategory(false)
        setShowAlert(false)

        // set 'All Media' category as active after deleting category
        const defaultCategory = props.media.category.find(item => item.slug === 'all media')
        props.updateSelectedCategory(defaultCategory ? defaultCategory._id : '')
        getAllMediaByType(activeTab, defaultCategory._id)
    }

    // common function to handle category name while add and update category
    const handleCheckCategoryName = (name, requestFrom, oldCategoryName = '') => {
        if (_.isEmpty(name)) {
            setErrorText('Name is required')
            return false
        }

        if (name.length > 100) {
            setErrorText('Name cannot be more than 100 characters.')
            return false
        }

        if (requestFrom === 'add_category' || (requestFrom === 'update_category' && oldCategoryName.trim().toLowerCase() !== name.toLowerCase())) {
            const activeCategories = category.filter(categoryList => categoryList.is_deleted !== true)
            const categoryIndex = activeCategories.findIndex(t => t.name.toLowerCase() === name.toLowerCase())
            if (categoryIndex !== -1) {
                setErrorText('Name already exist. Please add another category.')
                return false
            }
        }
        return true
    }

    const handleAddOrUpdateCategory = (requestFrom, categoryId = '', updateCategoryName = '') => {
        setAddOrUpdateCategory(requestFrom)
        setShowAddCategoryPopup(true)
        if (requestFrom === 'update_category') {
            setCategoryId(categoryId)
            setCategoryName(updateCategoryName)
            setOldCategoryName(updateCategoryName)
        }
    }

    function formatNumber(num) {
        if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K'
        } else {
            return num
        }
    }

    const renderPhotoVideoCounts = (categoryList, isPopup) => {
        const { photo_count, video_count, processed_photo_count, processed_video_count, slug } = categoryList
        let photoCount = 0
        let videoCount = 0
        if (isPopup) {
            photoCount = processed_photo_count
            videoCount = processed_video_count
        } else {
            photoCount = photo_count ? photo_count : 0
            videoCount = video_count ? video_count : 0
        }
        const isEmpty = (photoCount === 0 && videoCount === 0) ||
            (photoCount === undefined && videoCount === undefined)

        return isEmpty ?
            <PhotoVideoDiv className='d-flex pt-2 align-items-baseline lh-1'>
                Empty
            </PhotoVideoDiv>
            :
            <PhotoVideoDiv className='d-flex pt-2 align-items-baseline lh-1'>
                {photoCount !== 0 && photoCount !== undefined && (
                    <>
                        <i className='fas fa-image'></i>
                        <span className='padding-right'>{formatNumber(photoCount)}</span>
                    </>
                )}
                {photoCount !== 0 && videoCount !== 0 && <div className='small-dot'></div>}
                {videoCount !== 0 && videoCount !== undefined && (
                    <>
                        <i className='fas fa-video'></i>
                        <span>{formatNumber(videoCount)}</span>
                    </>
                )}
            </PhotoVideoDiv>
    }

    const hideCategoryPopup = () => {
        setShowAddCategoryPopup(false)
        setCategoryName('')
        setErrorText('')
        if (isOpenCategory === true) {
            setShowCategoryPopup(true)
        }
    }

    useEffect(() => {
        let el
        if (props.isPopup) {
            el = document.getElementById('media-modal-body')
            if (el) el.addEventListener('scroll', handleScroll)
        } else {
            window.addEventListener('scroll', handleScroll)
        }
        return () => {
            el ? el.removeEventListener('scroll', handleScroll) : window.removeEventListener('scroll', handleScroll)
        }
    })

    useEffect(() => {
        setPrevPage(currentPage - 1)
    }, [currentPage])

    const handleChangeType = (value) => {
        setActiveTab(value)
    }

    const mediaList = (e) => {
        const id = e.target.value
        if (e.target.checked) {
            setIsError((prevMedia) => {
                const item = media.find(item => item._id === id)
                if (item && item.isError) {
                  return [...prevMedia, item._id]
                }
                return prevMedia
              })
            setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id])
        } else {
            setSelectedIds((prevSelectedIds) => prevSelectedIds.filter(selectedId => selectedId !== id))
            setIsError((prevSelectedIds) => prevSelectedIds.filter(selectedId => selectedId !== id))
        }
    }

    const deleteMedia = () => {
        setShowAlert(true)
        props.setConfirmSweetAlert({ description: 'Are you sure you want to delete the selected media?' })
        setConfirmFor('delete_media')
    }

    const confirmationAlert = async () => {
        switch (confirmFor) {
            case 'delete_media': {
                const isAPISuccess = await props.removeMedia({ mediaIds: selectedIds })
                if (isAPISuccess === 1) {
                    setSelectedIds([])
                }
                break
            }
            case 'delete_category':
                deleteMediaCategory()
                break
            default:
                break
        }
    }

    const handleDeleteCategory = (id, name) => {
        setShowAlert(true)
        setCategoryId(id)
        props.setConfirmSweetAlert({
            description: `Are you sure you want to delete ${name} category?`
        })
        setConfirmFor('delete_category')
    }

    const handleCategory = (e) => {
        if (addOrUpdateCategory === 'add_category') {
            addNewCategory(e)
        } else {
            changeCategoryName(e)
        }
    }

    const commonProps = {
        isPopup: props.isPopup || false,
        showAddCategoryPopup: showAddCategoryPopup,
        onSelection: props.onSelection,
        isLoading: isLoading,
        handleCategory: handleCategory,
        addOrUpdateCategory: addOrUpdateCategory,
        renderPhotoVideoCounts: renderPhotoVideoCounts,
        isDisableCategory: isDisableCategory,
        isMediaLoadingOnScroll: isMediaLoadingOnScroll,
        handleChangeType: handleChangeType,
        handleDeleteCategory: handleDeleteCategory,
        handleAddOrUpdateCategory: handleAddOrUpdateCategory,
        deleteMedia: deleteMedia,
        mediaList: mediaList,
        activeTab: activeTab,
        isCategoryLoading: isCategoryLoading,
        hideCategoryPopup: hideCategoryPopup,
        selectedIds: selectedIds,
        setActiveTab: setActiveTab,
        categoryId: categoryId,
        setCategoryName: setCategoryName,
        setErrorText: setErrorText,
        categoryName: categoryName,
        setSelectedIds: setSelectedIds,
        getAllMediaByType: getAllMediaByType,
        errorText: errorText,
        setShowCategoryPopup: setShowCategoryPopup,
        showCategoryPopup: showCategoryPopup,
        setIsOpenCategory: setIsOpenCategory,
        newCategoryId: newCategoryId,
        setNewCategoryId: setNewCategoryId,
        setIsMoveCategory: setIsMoveCategory,
        setShowSelectButton: props.setShowSelectButton,
        prevSelectedMedia: props.prevSelectedMedia,
        setIsError: setIsError,
        isError: isError
    }

    return (
        <>
            {props.isPopup ?
                <MediaCommonContent {...commonProps} />
                :
                <PageWrapper>
                    <MediaCommonContent {...commonProps} />
                </PageWrapper>
            }
            {showAlert &&
                <ConfirmSweetAlert
                    onConfirm={() => {
                        props.setAlertLoader(true)
                        confirmationAlert()
                    }}
                    onCancel={() => setShowAlert(false)}
                />
            }
        </>
    )
}

Media.propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    promotion: PropTypes.object.isRequired,
    getAllMedia: PropTypes.func.isRequired,
    addCategory: PropTypes.func.isRequired,
    getAllCategory: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    setConfirmSweetAlert: PropTypes.func.isRequired,
    setAlertLoader: PropTypes.func.isRequired,
    removeConfirmSweetAlert: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired,
    updateFilter: PropTypes.func.isRequired,
    getMediaOnScroll: PropTypes.func.isRequired,
    addMedia: PropTypes.func.isRequired,
    updateCategory: PropTypes.func.isRequired,
    isPopup: PropTypes.bool,
    onSelection: PropTypes.func,
    removeMedia: PropTypes.func,
    updateSelectedCategory: PropTypes.func.isRequired,
    setShowSelectButton: PropTypes.func,
    prevSelectedMedia: PropTypes.array
}

const mapStateToProps = state => ({
    auth: state.auth,
    promotion: state.promotion,
    media: state.media
})

export default connect(mapStateToProps,
    {
        getAllMedia,
        addCategory,
        getAllCategory,
        updateCategory,
        setSweetAlert,
        setConfirmSweetAlert,
        setAlertLoader,
        removeConfirmSweetAlert,
        deleteCategory,
        updateFilter,
        addMedia,
        getMediaOnScroll,
        removeMedia,
        updateSelectedCategory
    })(Media)
