import axios from 'axios'
import _ from 'lodash'
import { getAppBaseUrl } from './../actions/api'

const BASE_URL = getAppBaseUrl()

export async function getPresignedUrl(fileName, contentType, mediaType, email = '', domain = '') {
    try {
        let url
        if (domain && domain !== window.location.hostname) {
            url = `/api/universal-chat/get-presigned-url?domain=${domain}`
        } else {
            url = '/api/upload/generate_presigned_url'
        }
        let res = await axios.post(BASE_URL + url, { file_name: fileName, content_type: contentType, media_type: mediaType, email: email })
        const message = _.get(res, 'data.message', 'Successful')
        if (res.data.status === 200) {
            return res.data.data
        } else {
            return { presigned_url: '', message: message }
        }
    } catch (err) {
        const errorMessage = _.get(err, 'response.data.message', err.message)
        return { presigned_url: '', message: errorMessage }
    }
}
