import {
    CHAT_USER_LIST,
    IS_LOADING,
    SET_MESSAGES,
    CHAT_USER_PROFILE_INFO,
    TIPS_LIST,
    IS_MORE_MESSAGE_LOADING,
    LOAD_MORE_MESSAGE,
    SET_CHAT_SELECTED_ID,
    SET_CHAT_MODEL_ID,
    ADD_NEW_MESSAGE_IN_END,
    UPDATE_MESSAGE,
    DELETE_MESSAGE,
    TOGGLE_CHAT_TIP_POPUP,
    UPDATE_USER_AMOUNT_SPENT,
    UPDATE_USER_LAST_SEEN,
    UPDATE_USER_NICKNAME,
    SET_ROTATE_CHAT_IMAGE,
    IS_MESSAGES_LOADING,
    SET_IS_CHAT_SCREEN_OPEN,
    UPDATE_USER_DETAILS,
    IS_READ_MESSAGE_COUNT,
    SHOW_BOOTSTRAP_NAVBAR,
    SET_SEARCH_INPUT_VALUE,
    DELETE_MESSAGE_FROM_ALL,
    UPDATE_USER_LAST_MESSAGE,
    SET_ROTATE_CHAT_MASS_MESSAGE,
    SET_UNLOCK_CONTENT_DETAIL,
    SET_SHOW_ADD_FUND_POPUP,
    SET_UNLOCK_DATA,
    SET_REMAIN_AMOUNT,
    SET_UPDATED_BALANCE,
    RESET_CHAT_DATA,
    CLEAR_CHAT_TEXT_INPUT,
    IS_CHAT_SCROLL_TO_BOTTOM,
    INC_MESSAGE_ID,
    UPDATE_USER_UNREAD_COUNT_IN_USER_LIST,
    ADD_USER_IN_ADMIN_CHAT_LIST,
    SET_WEBSITE_ID
} from './../actions/types'
import _ from 'lodash'

const initialState = {
    isAdmin: false,
    userInfo: {},
    messages: [],
    shouldLoadMessages: false,
    userList: [],
    isLoading: false,
    totalPages: 0,
    currentPage: 0,
    tipsList: [],
    totalTipsPages: 0,
    currentTipsPage: 0,
    userProfileInfo: {},
    sortBy: '',
    selectedUserId: '',
    selectedModelId: '',
    isLiveStreamAvailable: false,
    sortBySubscribers: '',
    showChatTipPopup: false,
    isMessagesLoading: false,
    isChatScreenOpen: false,
    showBootstrapNavbar: false,
    newChatMessage: [],
    searchedUsername: '',
    joinStream: true,
    unlockPaymentData: {},
    showAddFundPopup: false,
    unlockData: {},
    remainAmount: 0,
    updatedBalance: {},
    promotionDetails: {},
    clearChatInputText: false,
    isChatScrollToBottom: true,
    messageId: 1,
    websiteId: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CHAT_USER_LIST: {
            let users = action.payload.userList
            users = users.map(user => {
                user.last_message = user.last_message ? user.last_message.replace('<user_name>', user.name) : ''
                user.user_last_message = user.user_last_message ? user.user_last_message.replace('<model_name>', user.name) : ''
                return user
            })
            if (action.payload.isInfiniteScroll) {
                const existingUserIds = state.userList.length > 0
                    ? state.userList.map(user => user._id)
                    : []
                if (existingUserIds.length > 0) {
                    users = users.filter(user => !existingUserIds.includes(user._id))
                }
            }
            return {
                ...state,
                userList: action.payload.isInfiniteScroll
                    ? [...state.userList, ...users]
                    : [...users],
                totalPages: action.payload.totalPages,
                currentPage: parseInt(action.payload.currentPage, 10),
                sortBy: action.payload.sortBy,
                sortBySubscribers: action.payload.sortBySubscribers,
                isLoading: false
            }
        }
        case UPDATE_USER_DETAILS:
            const currentDomain = action.payload.domain
            action.payload.data.sort((a, b) => {

                // Check if domain matches the current domain
                const aIsCurrentDomain = a.domain === currentDomain ? 1 : 0
                const bIsCurrentDomain = b.domain === currentDomain ? 1 : 0

                // If one of them is the current domain, prioritize it
                if (aIsCurrentDomain !== bIsCurrentDomain) {
                    return bIsCurrentDomain - aIsCurrentDomain
                }

                // Otherwise, sort by last_message_time
                return new Date(b.last_message_time) - new Date(a.last_message_time)
            })

            return {
                ...state,
                userList: action.payload.data
            }
        case TIPS_LIST:
            return {
                ...state,
                tipsList: action.payload.tipsList,
                totalTipsPages: action.payload.totalPages,
                currentTipsPage: action.payload.currentPage,
                isLoading: false
            }
        case CHAT_USER_PROFILE_INFO: {
            let newProfiles = Object.assign(state.userProfileInfo)
            newProfiles[action.payload._id] = {
                profile: action.payload.avatarUrl,
                name: action.payload.name,
                nick_name: action.payload.nick_name,
                totalAmountSpent: action.payload.total_amount_spent,
                isLiveStreamAvailable: (state.userProfileInfo[action.payload._id] !== undefined && state.userProfileInfo[action.payload._id].isLiveStreamAvailable !== undefined) ? state.userProfileInfo[action.payload._id]?.isLiveStreamAvailable : action.payload.isLiveStreamAvailable,
                totalRefundAmount: action.payload.total_refund_amount !== undefined ? action.payload.total_refund_amount : 0.00,
                totalVoidAmount: action.payload.total_void_amount !== undefined ? action.payload.total_void_amount : 0.00,
                totalChargeBackAmount: action.payload.total_chargeback_amount !== undefined ? action.payload.total_chargeback_amount : 0.00,
                totalChargeBackCount: action.payload.total_chargeback_count !== undefined ? action.payload.total_chargeback_count : 0.00,
                lastSeen: action.payload.last_seen,
                wallet_amount: action.payload.wallet_amount
            }
            return {
                ...state,
                userProfileInfo: newProfiles
            }
        }
        case UPDATE_USER_NICKNAME: {
            let newProfiles = state.userProfileInfo
            if (newProfiles[action.payload.userId] !== undefined) {
                newProfiles[action.payload.userId] = {
                    ...newProfiles[action.payload.userId],
                    nick_name: action.payload.nickName
                }
            }
            return {
                ...state,
                userProfileInfo: newProfiles
            }
        }
        case IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case IS_MORE_MESSAGE_LOADING:
            return {
                ...state,
                newChatMessage: {
                    ...state.newChatMessage,
                    [action.payload.user_id]: {
                        ...state.newChatMessage[action.payload.user_id],
                        isMoreMessageLoading: action.payload.isLoading
                    }
                }
            }
        case SET_MESSAGES: {
            const { data } = action.payload
            const { messages, promotionDetails } = data
            let updatedPromotionDetails = state.promotionDetails
            if (promotionDetails) {
                updatedPromotionDetails = { ...updatedPromotionDetails, ...promotionDetails }
            }
            const userId = action.payload.user_id
            const groupMessagesByUserId = (messagesArray) => {
                if (messagesArray !== undefined) {
                    return messagesArray.reduce((acc, msg) => {
                        const otherUserId = msg.senderId === userId ? msg.senderId : msg.receiverId

                        // Initialize the structure for the user if it doesn't exist
                        if (!acc[otherUserId]) {
                            acc[otherUserId] = {
                                messages: [],
                                totalMessagesPage: 1,
                                currentMessagesPage: 1,
                                shouldLoadMoreMessages: false
                            }
                        }

                        const existingUserMessages = acc[otherUserId] ? acc[otherUserId].messages : []
                        const checkMessageIsExist = existingUserMessages.some(obj => obj._id === msg._id)

                        if (!checkMessageIsExist) {
                            acc[otherUserId].messages.push(msg)
                        }

                        return acc
                    }, {})
                }
                return {} // Return an empty object if messagesArray is undefined
            }

            // Get grouped messages
            const groupedMessages = groupMessagesByUserId(messages)
            return {
                ...state,
                promotionDetails: updatedPromotionDetails,
                newChatMessage: {
                    ...state.newChatMessage,
                    [userId]: {
                        messages: groupedMessages[userId] ? groupedMessages[userId].messages : [], // Use existing or empty array
                        totalMessagesPage: data.totalPages,
                        currentMessagesPage: data.currentPage,
                        shouldLoadMoreMessages: data.shouldLoadMoreMessages
                    }
                }
            }
        }
        case LOAD_MORE_MESSAGE: {
            let messages = []
            const userId = action.payload.user_id
            for (let i = 0; i < action.payload.data.messages.length; i++) {
                const resElement = action.payload.data.messages[i]
                let newChatMessage = state.newChatMessage[action.payload.user_id].messages || []
                for (let j = 0; j < newChatMessage.length; j++) {
                    const element = newChatMessage[j]

                    if (element._id !== resElement._id) {
                        messages.push(resElement)
                        break
                    }
                }
            }
            let allMessages = messages.concat(state.newChatMessage[userId].messages)

            return {
                ...state,
                newChatMessage: {
                    ...state.newChatMessage,
                    [userId]: {
                        messages: allMessages,
                        totalMessagesPage: action.payload.data.totalPages,
                        currentMessagesPage: action.payload.data.currentPage,
                        isMoreMessageLoading: false,
                        shouldLoadMoreMessages: action.payload.data.shouldLoadMoreMessages
                    }
                }
            }
        }
        case SET_CHAT_SELECTED_ID:
            return {
                ...state,
                selectedUserId: action.payload
            }
        case SET_CHAT_MODEL_ID:
            return {
                ...state,
                selectedModelId: action.payload
            }
        case ADD_NEW_MESSAGE_IN_END: {
            const receiveData = action.payload.data
            const user_id = action.payload.user_id
            let messages = state.newChatMessage[user_id] ? state.newChatMessage[user_id].messages : []
            // Find the message by old_message_id within the user's message array
            let existMessage = messages ? messages.filter((msg) => msg._id === receiveData.old_message_id) : []

            // Helper function to group messages by user_id
            let updatedMessages = [receiveData]
            const groupMessagesByUserId = (messagesArray) => {
                return messagesArray.reduce((acc, msg) => {
                    const otherUserId = msg.senderId === user_id ? msg.senderId : msg.receiverId
                    // Check if either senderId or receiverId is already present in the accumulator
                    const userMessages = acc[otherUserId] ? acc[otherUserId].messages : []
                    if (!userMessages) {
                        acc[otherUserId].message = []
                    }
                    // Add the message to both sender's and receiver's message list
                    let isMessageExist = false
                    if (_.isEmpty(msg.uniqueId) === false && _.isEmpty(userMessages) === false) {
                        isMessageExist = acc[otherUserId].messages.some(obj => obj.uniqueId === msg.uniqueId)
                    }
                    if (isMessageExist === false && _.isEmpty(userMessages) === false) {
                        acc[otherUserId].messages.push(msg)
                    }
                    return acc[otherUserId] ? acc[otherUserId].messages : []
                }, { ...state.newChatMessage })
            }

            // Find the message by _id within the user's message array
            let message = messages ? messages.filter((msg) => msg._id === receiveData._id) : []
            // Update message if processing is true
            if (message.length > 0 && message[0].processing === true && receiveData.processing === false) {
                let copyMessage = state.newChatMessage[user_id].messages || []
                const index = copyMessage.findIndex((msg) => msg._id === receiveData._id)
                if (index > -1) {
                    copyMessage.splice(index, 1)
                }
                updatedMessages = [receiveData]
            }

            // Update message while message unlock by model
            if (existMessage.length > 0) {
                let copyMessage = state.newChatMessage[user_id].messages || []
                const index = copyMessage.findIndex((msg) => msg._id === receiveData.old_message_id)
                if (index > -1) {
                    copyMessage.splice(index, 1)
                }
                updatedMessages = [receiveData]
            }

            // If processing is false, don't update the messages
            if (message.length > 0) {
                let copyMessage = state.newChatMessage[user_id].messages || []
                const index = copyMessage.findIndex((msg) => msg._id === receiveData._id)
                if (index > -1) {
                    copyMessage.splice(index, 1)
                }
                updatedMessages = [receiveData]
            }

            return {
                ...state,
                newChatMessage: {
                    ...state.newChatMessage,
                    [user_id]: {
                        messages: groupMessagesByUserId(updatedMessages),
                        totalMessagesPage: state.newChatMessage[user_id] ? state.newChatMessage[user_id].totalMessagesPage : 0,
                        currentMessagesPage: state.newChatMessage[user_id] ? state.newChatMessage[user_id].currentMessagesPage : 0,
                        shouldLoadMoreMessages: state.newChatMessage[user_id] ? state.newChatMessage[user_id].shouldLoadMoreMessages : false
                    }
                }
            }
        }
        case UPDATE_MESSAGE: {
            const userId = action.payload.user_id
            const messages = state.newChatMessage[userId] ? state.newChatMessage[userId].messages : []
            for (let index = 0; index < messages.length; index++) {
                let id = action.payload.data._id.toString()
                if (action.payload.sendingId !== '') {
                    id = action.payload.sendingId
                }
                if (messages[index]._id === id) {
                    messages[index] = action.payload.data // Only update the message data
                }
            }
            return {
                ...state,
                newChatMessage: {
                    ...state.newChatMessage,
                    [userId]: {
                        messages: messages,
                        totalMessagesPage: state.newChatMessage[userId] ? state.newChatMessage[userId].totalMessagesPage : 0,
                        currentMessagesPage: state.newChatMessage[userId] ? state.newChatMessage[userId].currentMessagesPage : 0,
                        shouldLoadMoreMessages: state.newChatMessage[userId] ? state.newChatMessage[userId].shouldLoadMoreMessages : false
                    }
                }
            }
        }
        case DELETE_MESSAGE: {
            const userId = action.payload.user_id
            let copyMessage = state.newChatMessage[userId].messages
            copyMessage = copyMessage.filter(message => message._id !== action.payload.id)
            return {
                ...state,
                newChatMessage: {
                    ...state.newChatMessage,
                    [userId]: {
                        messages: copyMessage
                    }
                }
            }
        }
        case TOGGLE_CHAT_TIP_POPUP: {
            return {
                ...state,
                showChatTipPopup: action.payload
            }
        }
        case UPDATE_USER_LAST_SEEN: {
            let userDetails = state.userProfileInfo
            let userId = action.payload
            userDetails[userId].lastSeen = new Date()
            return {
                ...state,
                userProfileInfo: userDetails
            }
        }
        case UPDATE_USER_AMOUNT_SPENT: {
            let userDetails = state.userProfileInfo
            let userList = state.userList
            const userId = action.payload.userId
            const userIndex = userList.findIndex((user) => user._id === userId)
            if (userIndex !== -1) {
                userList[userIndex].total_amount_spent = action.payload.totalAmountSpent
                userList[userIndex].wallet_amount = action.payload.walletAmount
            }
            if (userDetails[userId] !== undefined) {
                userDetails[userId].totalAmountSpent = action.payload.totalAmountSpent
                userDetails[userId].wallet_amount = action.payload.walletAmount
            }
            return {
                ...state,
                userProfileInfo: userDetails,
                userList: userList
            }
        }
        case SET_ROTATE_CHAT_IMAGE: {
            const imageRotate = action.payload.data
            const userId = action.payload.user_id

            // Find the index of the message
            const userMessages = state.newChatMessage[userId]?.messages || []
            const messageIndex = userMessages.findIndex(message => message._id === imageRotate._id)

            // Update the message if it exists
            if (messageIndex !== -1) {
                userMessages[messageIndex] = {
                    ...userMessages[messageIndex],
                    media: imageRotate.media,
                    ...(imageRotate.url && { url: imageRotate.url }) // Update `url` only if it exists in `imageRotate`
                }
            }

            return {
                ...state,
                newChatMessage: {
                    ...state.newChatMessage,
                    [userId]: {
                        ...state.newChatMessage[userId],
                        messages: userMessages
                    }
                }
            }
        }
        case IS_MESSAGES_LOADING:
            return {
                ...state,
                isMessagesLoading: action.payload
            }
        case SET_IS_CHAT_SCREEN_OPEN:
            return {
                ...state,
                isChatScreenOpen: action.payload
            }
        case IS_READ_MESSAGE_COUNT: {
            const updatedUserList = state.userList.map(user =>
                user._id === action.payload.userId
                    ? {
                        ...user,
                        unreadcount: {
                            ...user.unreadcount,
                            model_unread_message: 0
                        },
                        user_unread_message: 0
                    }
                    : user
            )
            return {
                ...state,
                userList: updatedUserList,
                totalPages: state.totalPages,
                currentPage: parseInt(state.currentPage, 10),
                sortBy: state.sortBy,
                sortBySubscribers: state.sortBySubscribers,
                isLoading: false
            }
        }
        case SHOW_BOOTSTRAP_NAVBAR: {
            return {
                ...state,
                showBootstrapNavbar: action.payload
            }
        }
        case UPDATE_USER_LAST_MESSAGE: {
            const { user_id, type, message, isAdmin, isMassMessage, domain, admin } = action.payload
            const userList = state.userList.map(user => {
                if (user._id === user_id) {
                    user.last_message = message ? message : isAdmin ? `You sent a ${type}` : `${user.name} sent a ${type}`
                    user.user_last_message = message ? message : isAdmin ? `${user.name} sent a ${type}` : `You sent a ${type}`
                    user.last_message_time = new Date().toISOString()
                }
                return user
            })
            if (isMassMessage === false) {
                userList.sort((a, b) => {

                    // Check if domain matches the current domain
                    if (admin === false) {
                        const aIsCurrentDomain = a.domain === domain ? 1 : 0
                        const bIsCurrentDomain = b.domain === domain ? 1 : 0

                        // If one of them is the current domain, prioritize it
                        if (aIsCurrentDomain !== bIsCurrentDomain) {
                            return bIsCurrentDomain - aIsCurrentDomain
                        }
                    }
                    // Otherwise, sort by last_message_time
                    return new Date(b.last_message_time) - new Date(a.last_message_time)
                })
            }
            return {
                ...state,
                userList: userList
            }
        }
        case SET_SEARCH_INPUT_VALUE: {
            const { searchedUsername } = action.payload
            return {
                ...state,
                searchedUsername
            }
        }
        case DELETE_MESSAGE_FROM_ALL: {
            const messageUdid = action.payload
            const chatAllMessage = state.newChatMessage
            const updatedChatAllMessage = Object.fromEntries(
                Object.entries(chatAllMessage).map(([userId, data]) => {
                    // Filter out the message with the matching message udid
                    const filteredMessages = data.messages.filter(
                        message => message.udid !== messageUdid && message.isLocked !== 'unlocked'
                    )
                    // Return the updated data for this user
                    return [userId, { ...data, messages: filteredMessages }]
                })
            )
            // Update the state with the modified chatAllMessage
            return {
                ...state,
                newChatMessage: updatedChatAllMessage
            }
        }
        case SET_ROTATE_CHAT_MASS_MESSAGE: {
            const messageUdid = action.payload.udid
            const chatAllMessage = state.newChatMessage
            const imageRotate = action.payload.data
            const updatedChatAllMessage = Object.fromEntries(
                Object.entries(chatAllMessage).map(([userId, data]) => {
                    // Filter out the message with the matching message udid
                    const userMessages = chatAllMessage[userId]?.messages || []
                    const messageIndex = userMessages.findIndex(message => message.udid === messageUdid)
                    if (messageIndex !== -1) {
                        userMessages[messageIndex] = {
                            ...userMessages[messageIndex],
                            media: imageRotate.media,
                            media_preview: imageRotate.media_preview,
                            ...(imageRotate.url && { url: imageRotate.url }) // Update `url` only if it exists in `imageRotate`
                        }
                    }
                    // Return the updated data for this user
                    return [userId, { ...data, messages: userMessages }]
                })
            )
            return {
                ...state,
                newChatMessage: updatedChatAllMessage
            }
        }
        case SET_UNLOCK_CONTENT_DETAIL: {
            return {
                ...state,
                unlockPaymentData: action.payload
            }
        }
        case SET_SHOW_ADD_FUND_POPUP: {
            return {
                ...state,
                showAddFundPopup: action.payload
            }
        }
        case SET_UNLOCK_DATA: {
            return {
                ...state,
                unlockData: action.payload
            }
        }
        case SET_REMAIN_AMOUNT: {
            return {
                ...state,
                remainAmount: action.payload
            }
        }
        case SET_UPDATED_BALANCE: {
            return {
                ...state,
                updatedBalance: action.payload
            }
        }
        case CLEAR_CHAT_TEXT_INPUT: {
            return {
                ...state,
                clearChatInputText: action.payload
            }
        }
        case IS_CHAT_SCROLL_TO_BOTTOM: {
            return {
                ...state,
                isChatScrollToBottom: action.payload
            }
        }
        case RESET_CHAT_DATA: {
            return initialState
        }
        case INC_MESSAGE_ID: {
            return {
                ...state,
                messageId: state.messageId + 1
            }
        }
        case UPDATE_USER_UNREAD_COUNT_IN_USER_LIST: {
            const userId = action.payload.userId
            const isAdmin = action.payload.isAdmin
            const updatedUserList = state.userList.map(user => {
                if (user._id === userId) {
                    let updatedUser = { ...user }

                    if (isAdmin === true) {
                        updatedUser.unreadcount = {
                            model_unread_message: user.unreadcount.model_unread_message + 1
                        }
                    } else {
                        updatedUser.user_unread_message = user.user_unread_message + 1
                    }
                    return updatedUser
                }
                return user
            })

            return {
                ...state,
                userList: updatedUserList
            }
        }
        case ADD_USER_IN_ADMIN_CHAT_LIST: {
            let userLists = state.userList
            const isUserExist = userLists.some(user => user._id === action.payload._id)
            if (isUserExist === false) {
                userLists = [action.payload, ...state.userList]
            }
            return {
                ...state,
                userList: userLists
            }
        }
        case SET_WEBSITE_ID: {
            return {
                ...state,
                websiteId: action.payload
            }
        }
        default:
            return state
    }
}
