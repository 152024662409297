import * as React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import jwt_decode from 'jwt-decode'
import setAuthToken from './utils/setAuthToken'

import { logoutUser } from './actions/authActions'

import HomePage from './components/HomePage.react'
import PrivateRoute from './components/private-route/PrivateRoute'
import Startup from './components/StartUp'
import GoogleAnalytics from './components/Layouts/GoogleAnalytics'
import OneSignalWrapper from './components/Layouts/OneSignal'

import { getUserDetails, setIsProfileReadyAction } from './actions/authActions'
import 'tabler-react/dist/Tabler.css'
import Loader from './components/Layouts/Loader'
import Chat from './components/chat-split-ui/Chat'

const TipsPage = React.lazy(() => import('./components/tips/TipsPage.react'))
const ChatPage = React.lazy(() => import('./components/chat-new/ChatPage'))
const LoginPage = React.lazy(() => import('./components/login/LoginPage'))
const ForgotPasswordPage = React.lazy(() => import('./components/auth/ForgotPasswordPage'))
const MembershipPage = React.lazy(() => import('./components/ccbill/MembershipBillingPage'))
const ChangePasswordPage = React.lazy(() => import('./components/auth/ChangePasswordPage'))
const RegisterPage = React.lazy(() => import('./components/register/RegisterPage'))
const TransactionPage = React.lazy(() => import('./components/transactions/Transactions'))
const SubscribersPage = React.lazy(() => import('./components/subscribers/SubscribersPage'))
const AppSettingsPage = React.lazy(() => import('./components/app-settings/AppSettings'))
const GenerateSubscriptionURLPage = React.lazy(() => import('./components/app-settings/GenerateSubscriptionURL'))
const LinksPageSettings = React.lazy(() => import('./components/app-settings/LinksPageSettings'))
const MassMessagesPage = React.lazy(() => import('./components/mass-messages/MassMessagesPage'))
const SuperAdminToolsPage = React.lazy(() => import('./components/SuperAdminTools/SuperAdminToolsPage'))
const SuperAdminToolsLookupPage = React.lazy(() => import('./components/SuperAdminTools/SuperAdminToolsLookupPage'))
const EditTermsOfService = React.lazy(() => import('./components/terms-of-service/EditTermsOfService'))
const EditPrivacyPolicy = React.lazy(() => import('./components/privacy-policy/EditPrivacyPolicy'))
const EditDMCA = React.lazy(() => import('./components/dmca/EditDMCA'))
const Edit2257 = React.lazy(() => import('./components/2257/Edit2257'))
const EditFaq = React.lazy(() => import('./components/faq/EditFaq'))
const EditMaintenanceMode = React.lazy(() => import('./components/maintenance/EditMaintenanceMode.js'))
const UploadPhotoID = React.lazy(() => import('./components/upload-photo-id/UploadPhotoID'))
const LikeTheWebsite = React.lazy(() => import('./components/like-the-website/LikeTheWebsite'))
const GalleryPage = React.lazy(() => import('./components/gallery/GalleryPage.react'))
const SingleGalleryPage = React.lazy(() => import('./components/gallery/SingleGalleryPage'))
const TermsOfService = React.lazy(() => import('./components/terms-of-service/TermsOfService'))
const PrivacyPolicy = React.lazy(() => import('./components/privacy-policy/PrivacyPolicy'))
const Dmca = React.lazy(() => import('./components/dmca/DMCA'))
const Render2257 = React.lazy(() => import('./components/2257/2257'))
const FaqPage = React.lazy(() => import('./components/faq/Faq'))
const ContactUs = React.lazy(() => import('./components/contact-us/Contact'))
const Error404 = React.lazy(() => import('./pages/404.react'))
const LinksPage = React.lazy(() => import('./components/links-page/LinksPage'))
const TipsList = React.lazy(() => import('./components/chat-new/TipsList'))
const LockedExclusiveContent = React.lazy(() => import('./components/locked-exclusive-content/lockedExclusiveContent'))
const AddProduct = React.lazy(() => import('./components/shop-admin/AddProduct'))
const EditProduct = React.lazy(() => import('./components/shop-admin/EditProduct'))
const ProductListAdmin = React.lazy(() => import('./components/shop-admin/ProductListAdmin'))
const ProductListUser = React.lazy(() => import('./components/shop-user/ProductListUser'))
const ViewProduct = React.lazy(() => import('./components/shop-user/ViewProduct'))
const Cart = React.lazy(() => import('./components/shop-user/Cart'))
const CCBillLogs = React.lazy(() => import('./components/SuperAdminTools/CCBillLogs'))
const Checkout = React.lazy(() => import('./components/shop-user/Checkout'))
const ShippingAddressList = React.lazy(() => import('./components/shop-user/ShippingAddressList'))
const AddShippingAddress = React.lazy(() => import('./components/shop-user/AddShippingAddress'))
const EditShippingAddress = React.lazy(() => import('./components/shop-user/EditShippingAddress'))
const OrderHistory = React.lazy(() => import('./components/shop-user/OrderHistory'))
const OrderDetails = React.lazy(() => import('./components/shop-user/OrderDetails'))
const OrderList = React.lazy(() => import('./components/shop-admin/OrderList'))
const EditOrder = React.lazy(() => import('./components/shop-admin/EditOrderDetails'))
const LandingPageSettings = React.lazy(() => import('./components/app-settings/LandingPageSettings'))
const UploadThemeImage = React.lazy(() => import('./components/app-settings/UploadThemeImage'))
const ReportingPurchasedByPage = React.lazy(() => import('./components/reporting-purchased-by/ReportingPurchasedByPage'))
const BackgroundSettings = React.lazy(() => import('./components/app-settings/BackgroundSettings'))
const ChatSettings = React.lazy(() => import('./components/app-settings/ChatSettings'))
const ChatSlot = React.lazy(() => import('./components/chat-new/ChatSlot'))
const UserChatBookingPage = React.lazy(() => import('./components/chat-new/UserChatBookingPage'))
const ReportingPage = React.lazy(() => import('./components/app-settings/ReportingPage'))
const SubscribeForm = React.lazy(() => import('./components/ccbill/SubscribeForm'))
const CCBillRestApiSettings = React.lazy(() => import('./components/app-settings/CCBillRestApiSettings'))
const LiveStream = React.lazy(() => import('./components/live-stream/LiveStream'))
const LiveStreamingSettings = React.lazy(() => import('./components/app-settings/LiveStreamingSettings'))
const EmailConfigurationSetting = React.lazy(() => import('./components/app-settings/EmailConfiguration'))
const LiveStreamReports = React.lazy(() => import('./components/live-stream/LiveStreamReports'))
const StaggerOverTimeSettings = React.lazy(() => import('./components/app-settings/StaggerOverTime'))
const WelcomeMessage = React.lazy(() => import('./components/mass-messages/WelcomeMessagePage'))
const PurchasedContents = React.lazy(() => import('./components/Layouts/PurchasedContents'))
const PurchasedContentSettings = React.lazy(() => import('./components/purchased-content-settings/PurchasedContentSettings'))
const ExportUsersCSV = React.lazy(() => import('./components/export-users/ExportUsersCSV'))
const LandingPagePreview = React.lazy(() => import('./components/landing-page/LandingPagePreview'))
const CCBillSettings = React.lazy(() => import('./components/app-settings/CCBillSettings'))
const AWSSettings = React.lazy(() => import('./components/app-settings/AWSSettings'))
const GroupLiveStream = React.lazy(() => import('./components/live-stream/GroupLiveStream'))
const GroupLiveStreamWrapper = React.lazy(() => import('./components/live-stream/GroupLiveStreamWrapper'))
const PayPerMessageSettings = React.lazy(() => import('./components/app-settings/PayPerMessageSettings'))
const PlatformSettings = React.lazy(() => import('./components/app-settings/PlatformSettings'))
const PurchaseNotificationSettings = React.lazy(() => import('./components/app-settings/PurchaseNotificationSettings'))
const SeoSettings = React.lazy(() => import('./components/app-settings/SeoSettings'))
const OptInEmailVerification = React.lazy(() => import('./components/opt-in-email/OptInEmailVerification'))
const UpdateProfile = React.lazy(() => import('./components/account/UpdateProfile'))
const ChangePassword = React.lazy(() => import('./components/account/ChangePassword'))
const CancelSubscription = React.lazy(() => import('./components/account/CancelSubscription'))
const NotificationPreference = React.lazy(() => import('./components/account/NotificationPreference'))
const PaymentMethod = React.lazy(() => import('./components/account/PaymentMethod'))
const OrderLists = React.lazy(() => import('./components/account/OrderLists'))
const ProfilePage = React.lazy(() => import('./components/account/ProfilePage'))
const PromotionSettings = React.lazy(() => import('./components/app-settings/PromotionSettings'))
const AddNewPaymentMethod = React.lazy(() => import('./components/account/AddNewPaymentMethod'))
const MenuSettings = React.lazy(() => import('./components/app-settings/MenuSettings'))
const SubscriptionPageSettings = React.lazy(() => import('./components/app-settings/SubscriptionPageSettings'))
const TipPageSettings = React.lazy(() => import('./components/app-settings/TipPageSettings'))
const PromotionReports = React.lazy(() => import('./components/promotion/PromotionReports'))
const NewSignUpPageSettings = React.lazy(() => import('./components/app-settings/NewSignUpPageSettings'))
const Theme2Settings = React.lazy(() => import('./components/app-settings/Theme2Settings'))
const MembershipPriceSettings = React.lazy(() => import('./components/app-settings/MembershipPriceSetting'))
const OptInEmailReporting = React.lazy(() => import('./components/opt-in-email/OptInEmailReporting'))
const UserCardList = React.lazy(() => import('./components/user-card-list/UserCardList'))
const StickyIoSettings = React.lazy(() => import('./components/app-settings/StickyIoSettings'))
const WebsiteSettings = React.lazy(() => import('./components/app-settings/WebsiteSettings'))
const ServiceStopPage = React.lazy(() => import('./components/Layouts/ServiceStopPage'))
const StickyIoTransactions = React.lazy(() => import('./components/sticky-io/stickyIoTransactions'))
const ResubscribeOfferSettings = React.lazy(() => import('./components/app-settings/ResubscribeOfferSettings'))
const FlexForm = React.lazy(() => import('./components/Layouts/FlexForm'))
const UserReport = React.lazy(() => import('./components/user-report/UserReport'))
const StickyIoSubscriptionProductList = React.lazy(() => import('./components/sticky-io/stickyIoSubscriptionProductList'))
const SuperAdminToolsStickyIoLookupPage = React.lazy(() => import('./components/SuperAdminTools/SuperAdminToolsStickyIoLookupPage'))
const WatermarkSettings = React.lazy(() => import('./components/app-settings/WatermarkSettings'))
const Notification = React.lazy(() => import('./components/Layouts/Notification'))
const WebsiteSettingsPage = React.lazy(() => import('./components/app-settings/WebsiteSettingsPage'))
const ThemeSettings = React.lazy(() => import('./components/app-settings/ThemeSettings'))
const NotificationRedirectPage = React.lazy(() => import('./components/Layouts/NotificationRedirectPage'))
const BannerImageTextSettings = React.lazy(() => import('./components/app-settings/BannerImageTextSettings'))
const MigrateSubscriptionsSettings = React.lazy(() => import('./components/app-settings/MigrateSubscriptionsSettings'))
const MinimumAndMaximumAmounts = React.lazy(() => import('./components/app-settings/MinimumAndMaximumAmounts'))
const AgeModal = React.lazy(() => import('./components/app-settings/AgeModal'))
const ContactUsSettings = React.lazy(() => import('./components/app-settings/ContactUsSettings'))
const LoginSignupTitle = React.lazy(() => import('./components/app-settings/LoginSignupTitle'))
const MiscellaneousPopUp = React.lazy(() => import('./components/app-settings/MiscellaneousPopUp'))
const Shop = React.lazy(() => import('./components/app-settings/Shop'))
const HybridPaymentSettings = React.lazy(() => import('./components/app-settings/HybridPaymentSettings'))
const CustomMenuLinksSettings = React.lazy(() => import('./components/app-settings/CustomMenuLinks'))
const InfluencerHelpDetailPage = React.lazy(() => import('./components/Layouts/InfluencerHelpDetailPage'))
const InfluencerHelp = React.lazy(() => import('./components/Layouts/InfluencerHelp'))
const MiscellaneousSettings = React.lazy(() => import('./components/app-settings/MiscellaneousSettings'))
const CSVDownload = React.lazy(() => import('./components/app-settings/CSVDownload'))
const RecaptchaSettings = React.lazy(() => import('./components/app-settings/RecaptchaSettings'))
const unlockedContentUserList = React.lazy(() => import('./components/unlocked-content-user-list/UnlockedContentUserList'))
const HealthInfo = React.lazy(() => import('./components/health-info/HealthInfo'))
const TimestampVisibility = React.lazy(() => import('./components/app-settings/TimestampVisibility'))
const UniversalBlock = React.lazy(() => import('./components/app-settings/UniversalBlock'))
const universalBlockLogs = React.lazy(() => import('./components/universal-block/universalBlockLogs'))
const helpSettings = React.lazy(() => import('./components/app-settings/HelpSettings'))
const publicPostSettings = React.lazy(() => import('./components/app-settings/PublicPostSettings'))
const userUtmParams = React.lazy(() => import('./components/user-utm-params/userUtmParams'))
const Cancellation = React.lazy(() => import('./components/cancellation/cancellation'))
const CancellationReasonSettings = React.lazy(() => import('./components/app-settings/CancellationReasonSettings'))
const redirectUrl = React.lazy(() => import('./components/redirect-url/redirectUrl'))
const redirectUrlList = React.lazy(() => import('./components/redirect-url/redirectUrlList'))
const RedirectRouteInformation = React.lazy(() => import('./components/redirect-url/redirectRouteInformation'))
const ForumPaySettings = React.lazy(() => import('./components/app-settings/ForumPaySettings'))
const blogCommentSettings = React.lazy(() => import('./components/app-settings/BlogCommentSettings'))
const blogInfo = React.lazy(() => import('./components/blogs/BlogInfo'))
const HideEarning = React.lazy(() => import('./components/app-settings/HideEarning'))
const FeedTags = React.lazy(() => import('./components/app-settings/FeedTagsSettings'))
const ShowPaymentIcons = React.lazy(() => import('./components/app-settings/ShowPaymentIcons'))
const Wallet = React.lazy(() => import('./components/wallet/Wallet'))
const AnnouncementBanner = React.lazy(() => import('./components/app-settings/AnnouncementBanner'))
const VoidRefundAndChargeback = React.lazy(() => import('./components/void-refund-and-chargeback/VoidRefundAndChargeback'))
const ChatListSettings = React.lazy(() => import('./components/app-settings/ChatListSettings'))
const UserPurchaseHistory = React.lazy(() => import('./components/user-purchase-history/UserPurchaseHistory'))
const ShowMediaCountInExclusiveContentSettings = React.lazy(() => import('./components/app-settings/ShowMediaCountInExlusiveContentSettings'))
const influencerActivity = React.lazy(() => import('./components/influencerActivity/influencerActivity'))
const ManageMassMessages = React.lazy(() => import('./components/mass-messages/ManageMessagesPage'))
const Navbar = React.lazy(() => import('./components/app-settings/NavbarSettings'))
const ReportingMenu = React.lazy(() => import('./components/Layouts/ReportingMenu'))
const CustomMenuLinks = React.lazy(() => import('./components/Layouts/CustomMenuLinks'))
const WebPushNotificationSettings = React.lazy(() => import('./components/app-settings/WebPushNotificationSettings'))
const SubscriptionVideoMessage = React.lazy(() => import('./components/Layouts/VideoSubscribePage.js'))
const UniversalLoginSetting = React.lazy(() => import('./components/app-settings/UniversalLoginSetting'))
const SubscriptionEmailNotification = React.lazy(() => import('./components/app-settings/SubscriptionEmailNotification.js'))
const DisplayPaginationOnExclusiveContent = React.lazy(() => import('./components/app-settings/DisplayPaginationOnExclusiveContent.js'))
const CouponSettings = React.lazy(() => import('./components/app-settings/CouponSettings.js'))
const CouponDetails = React.lazy(() => import('./components/coupon/CouponDetails.js'))
const CouponReports = React.lazy(() => import('./components/coupon/CouponReports.js'))
const ContentRemoval = React.lazy(() => import('./components/content-removal/ContentRemoval'))
const EditContentRemoval = React.lazy(() => import('./components/content-removal/EditContentRemoval'))
const MediaManager = React.lazy(() => import('./components/media/Media'))
const EnableRedisCache = React.lazy(() => import('./components/app-settings/EnableRedisCache.js'))

// Check for token to keep user logged in
if (localStorage.AuthToken) {
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.AuthToken)

    // Check for expired token
    const currentTime = Date.now() / 1000
    if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser())
    } else {
        // Set auth token header auth
        setAuthToken(localStorage.AuthToken)

        // Set user and isAuthenticated
        // store.dispatch(setCurrentUser(decoded))

        // Get Current user details
        getUserDetails()
    }
} else {
    store.dispatch(setIsProfileReadyAction())
}

const ROLE_ADMIN = 'admin'
const ROLE_MODEL = 'model'
const ROLE_CONTENT_MANAGER = 'content_manager'
const ROLE_USER = 'user'
const ROLE_SUPPORT = 'support'
const ROLE_SUB_ADMIN = 'sub_admin'
const ROLE_PROXY_USER = 'proxy_user'
const ROLE_LIVE_STREAM_MANAGER = 'live_stream_manager'

const ALLOW_ALL = [ROLE_USER, ROLE_CONTENT_MANAGER, ROLE_MODEL, ROLE_ADMIN, ROLE_SUPPORT, ROLE_SUB_ADMIN]
const ALLOW_MODEL_ADMIN = [ROLE_MODEL, ROLE_ADMIN, ROLE_SUB_ADMIN]
const ALLOW_ADMIN = [ROLE_ADMIN, ROLE_SUB_ADMIN]
const ALLOW_ALL_ADMINS = [ROLE_ADMIN, ROLE_MODEL, ROLE_CONTENT_MANAGER, ROLE_SUB_ADMIN]
const ALLOW_SUPPORT_ADMIN = [ROLE_SUPPORT, ROLE_ADMIN, ROLE_SUB_ADMIN]
const ALLOW_CONTENT_MANAGER_ADMIN = [ROLE_CONTENT_MANAGER, ROLE_ADMIN, ROLE_SUB_ADMIN]
const ALLOW_ONLY_SUPER_ADMIN = [ROLE_ADMIN]
const ALLOW_ALL_ADMIN_SUPPORT = [ROLE_ADMIN, ROLE_CONTENT_MANAGER, ROLE_MODEL, ROLE_SUB_ADMIN, ROLE_SUPPORT]
const ALLOW_MODEL_CONTENT_MANAGER = [ROLE_MODEL, ROLE_ADMIN, ROLE_SUB_ADMIN, ROLE_CONTENT_MANAGER]
const ALLOW_ONLY_USER = [ROLE_USER]
const ALLOW_MODEL_SUPER_ADMIN = [ROLE_ADMIN, ROLE_MODEL]
const ALLOW_LIVE_STREAM_MANAGER_ADMIN = [ROLE_USER, ROLE_CONTENT_MANAGER, ROLE_MODEL, ROLE_ADMIN, ROLE_SUPPORT, ROLE_SUB_ADMIN, ROLE_LIVE_STREAM_MANAGER]
const ALLOW_ALL_ADMIN_SUPPORT_LIVE_STREAM_MANAGER = [ROLE_CONTENT_MANAGER, ROLE_MODEL, ROLE_ADMIN, ROLE_SUPPORT, ROLE_SUB_ADMIN, ROLE_LIVE_STREAM_MANAGER, ROLE_USER, ROLE_PROXY_USER]

class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <Startup>
                    <Router>
                        <GoogleAnalytics>
                            <OneSignalWrapper />
                            <React.Suspense fallback={
                                <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
                                    <Loader loading={true} size={10} />
                                </div>
                            }>
                                <GroupLiveStreamWrapper>
                                    <Switch>
                                        <Route exact path='/' component={HomePage} />
                                        <Route exact path='/home' component={HomePage} />
                                        <Route exact path='/forgot-password' component={ForgotPasswordPage} />
                                        <Route exact path='/change-password/:token' component={ChangePasswordPage} />
                                        <Route exact path='/login' component={LoginPage} />
                                        <Route exact path='/register' component={RegisterPage} />
                                        <Route exact path='/tips' component={TipsPage} />
                                        <Route exact path='/subscription-video' component={SubscriptionVideoMessage} />
                                        <Route exact path='/chat' component={ChatPage} />
                                        <PrivateRoute exact path='/tips-list' component={TipsList} allow={ALLOW_ALL_ADMINS} />
                                        <PrivateRoute exact path='/private-chat/:id?' component={Chat} allow={ALLOW_ALL_ADMIN_SUPPORT_LIVE_STREAM_MANAGER} />
                                        <PrivateRoute exact path='/membership' component={MembershipPage} allow={ALLOW_ALL} />
                                        <Route exact path='/404' component={Error404} />
                                        <Route exact path='/gallery' component={GalleryPage} />
                                        <Route exact path='/single-gallery/:id' component={SingleGalleryPage} />
                                        <Route exact path='/like-the-website' component={LikeTheWebsite} />
                                        <Route exact path='/shop' component={ProductListUser} />
                                        <PrivateRoute exact path='/shop/view-cart' component={Cart} allow={ALLOW_ALL} />
                                        <PrivateRoute exact path='/shop/checkout/:id?' component={Checkout} allow={ALLOW_ALL} />
                                        <PrivateRoute exact path='/shop/shipping-details' component={ShippingAddressList} allow={ALLOW_ALL} />
                                        <PrivateRoute exact path='/shop/shipping-details/add' component={AddShippingAddress} allow={ALLOW_ALL} />
                                        <PrivateRoute exact path='/shop/shipping-details/edit/:id' component={EditShippingAddress} allow={ALLOW_ALL} />
                                        <PrivateRoute exact path='/shop/order-history' component={OrderHistory} allow={ALLOW_ALL} />
                                        <PrivateRoute exact path='/shop/order-history/:id' component={OrderDetails} allow={ALLOW_ALL} />
                                        <Route exact path='/shop/:id' component={ViewProduct} />
                                        <Route exact path='/terms-of-service' component={TermsOfService} />
                                        <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                                        <Route exact path='/dmca' component={Dmca} />
                                        <Route exact path='/2257' component={Render2257} />
                                        <Route exact path='/faq' component={FaqPage} />
                                        <Route exact path='/contact-us' component={ContactUs} />
                                        <Route exact path='/links' component={LinksPage} />
                                        <PrivateRoute exact path='/upload-photo-id' component={UploadPhotoID} allow={ALLOW_ALL} />
                                        <PrivateRoute exact path='/admin/subscribers' component={SubscribersPage} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                        <PrivateRoute exact path='/admin/settings' component={AppSettingsPage} allow={ALLOW_ALL_ADMINS} />
                                        <PrivateRoute exact path='/admin/settings/links-page-settings' component={LinksPageSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/landing-page-settings' component={LandingPageSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/edit-terms-of-service' component={EditTermsOfService} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/edit-privacy-policy' component={EditPrivacyPolicy} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/edit-dmca' component={EditDMCA} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/edit-2257' component={Edit2257} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/edit-faq' component={EditFaq} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/edit-maintenance-mode' component={EditMaintenanceMode} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/mass-messages' component={MassMessagesPage} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                        <PrivateRoute exact path='/admin/locked-exclusive-content' component={LockedExclusiveContent} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                        <PrivateRoute exact path='/admin/transactions' component={TransactionPage} allow={ALLOW_SUPPORT_ADMIN} />
                                        <PrivateRoute exact path='/admin/super-admin-tools' component={SuperAdminToolsPage} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/super-admin-tools/lookup' component={SuperAdminToolsLookupPage} allow={ALLOW_SUPPORT_ADMIN} />
                                        <PrivateRoute exact path='/admin/shop/add-product' component={AddProduct} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/shop/edit-product/:id' component={EditProduct} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/shop/product-list' component={ProductListAdmin} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/shop/order-list' component={OrderList} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/shop/order/edit/:id' component={EditOrder} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/logs' component={CCBillLogs} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/upload-theme-image' component={UploadThemeImage} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/upload-theme2-banner-image' component={UploadThemeImage} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/upload-theme-image/model-profile' component={UploadThemeImage} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/purchased-by/mass-message/:_id/:promotion_id?' component={ReportingPurchasedByPage} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                        <PrivateRoute exact path='/admin/promotion-subscribe-users/:promotion_id' component={ReportingPurchasedByPage} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                        <PrivateRoute exact path='/admin/purchased-by/locked-exclusive-content/:blogId/:promotion_id?' component={ReportingPurchasedByPage} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                        <PrivateRoute exact path='/admin/generate-subscription-url' component={GenerateSubscriptionURLPage} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/background-settings' component={BackgroundSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/chat-booking-settings' component={ChatSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/set-chat-availability/:page' component={ChatSlot} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/book-slot/:page' component={UserChatBookingPage} allow={ALLOW_ALL} />
                                        <PrivateRoute exact path='/admin/settings/reporting-page' component={ReportingPage} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/live-stream' component={LiveStream} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/live-streaming-settings' component={LiveStreamingSettings} allow={ALLOW_CONTENT_MANAGER_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/email-configuration' component={EmailConfigurationSetting} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/live-stream-sessions/:id?' component={LiveStreamReports} allow={ALLOW_ALL_ADMINS} />
                                        <PrivateRoute exact path='/admin/settings/stagger-message-settings' component={StaggerOverTimeSettings} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/email-configuration' component={EmailConfigurationSetting} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/welcome-messages' component={WelcomeMessage} allow={ALLOW_ALL_ADMINS} />
                                        <PrivateRoute exact path='/purchased-contents/:id?' component={PurchasedContents} allow={ALLOW_ALL} />
                                        <PrivateRoute exact path='/admin/settings/purchased-content-settings' component={PurchasedContentSettings} allow={[ROLE_MODEL, ROLE_ADMIN]} />
                                        <PrivateRoute exact path='/admin/settings/export-users' component={ExportUsersCSV} allow={ALLOW_ALL_ADMINS} />
                                        <PrivateRoute exact path='/admin/purchased-by/welcome-message/:_id' component={ReportingPurchasedByPage} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                        <PrivateRoute exact path='/landing-page-preview' component={LandingPagePreview} allow={ALLOW_ALL_ADMIN_SUPPORT} />
                                        <PrivateRoute exact path='/admin/settings/ccbill-settings' component={CCBillSettings} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/aws-settings' component={AWSSettings} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/group-live-stream' component={GroupLiveStream} allow={ALLOW_LIVE_STREAM_MANAGER_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/platform-settings' component={PlatformSettings} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/purchase-notification-settings' component={PurchaseNotificationSettings} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/pay-per-message' component={PayPerMessageSettings} allow={ALLOW_ADMIN} />
                                        <Route exact path='/opt-in-email-verification/:token' component={OptInEmailVerification} />
                                        <PrivateRoute exact path='/change-email-verification/:token' component={OptInEmailVerification} allow={ALLOW_ONLY_USER} />
                                        <PrivateRoute exact path='/admin/settings/seo-setting' component={SeoSettings} allow={[ROLE_ADMIN]} />
                                        <PrivateRoute exact path='/subscribe' component={SubscribeForm} allow={ALLOW_ALL} />
                                        <PrivateRoute exact path='/admin/settings/ccbill-rest-api-settings' component={CCBillRestApiSettings} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/profile' component={ProfilePage} allow={ALLOW_LIVE_STREAM_MANAGER_ADMIN} />
                                        <PrivateRoute exact path='/profile/update' component={UpdateProfile} allow={ALLOW_LIVE_STREAM_MANAGER_ADMIN} />
                                        <PrivateRoute exact path='/profile/change-password' component={ChangePassword} allow={ALLOW_LIVE_STREAM_MANAGER_ADMIN} />
                                        <PrivateRoute exact path='/profile/cancel-subscription' component={CancelSubscription} allow={ALLOW_ONLY_USER} />
                                        <PrivateRoute exact path='/profile/notification-preference' component={NotificationPreference} allow={ALLOW_ALL} />
                                        <PrivateRoute exact path='/profile/payment-method' component={PaymentMethod} allow={ALLOW_ONLY_USER} />
                                        <PrivateRoute exact path='/profile/order-list' component={OrderLists} allow={ALLOW_ALL} />
                                        <PrivateRoute exact path='/admin/settings/promotion-settings' component={PromotionSettings} allow={ALLOW_ALL_ADMINS} />
                                        <PrivateRoute exact path='/profile/add-new-payment-method' component={AddNewPaymentMethod} allow={ALLOW_ONLY_USER} />
                                        <PrivateRoute exact path='/admin/settings/menu-settings' component={MenuSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/subscription-page-settings' component={SubscriptionPageSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/tip-page-settings' component={TipPageSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/promotion-reports' component={PromotionReports} allow={ALLOW_ALL_ADMINS} />
                                        <PrivateRoute exact path='/admin/settings/signup-design' component={NewSignUpPageSettings} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/theme-2-settings' component={Theme2Settings} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/membership-price-settings' component={MembershipPriceSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/opt-in-report' component={OptInEmailReporting} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/user-card-list' component={UserCardList} allow={[ROLE_SUPPORT, ROLE_ADMIN]} />
                                        <PrivateRoute exact path='/admin/settings/sticky-io-setting' component={StickyIoSettings} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/website-settings' component={WebsiteSettings} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/sticky-io-transactions' component={StickyIoTransactions} allow={[ROLE_ADMIN, ROLE_SUPPORT]} />
                                        <PrivateRoute exact path='/admin/ccbill-test-flex-form' component={FlexForm} allow={ALLOW_CONTENT_MANAGER_ADMIN} />
                                        <Route exact path='/service-stopped' component={ServiceStopPage} />
                                        <PrivateRoute exact path='/admin/settings/resubscribe-offer-settings' component={ResubscribeOfferSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/user-report' component={UserReport} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/watermark' component={WatermarkSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/sticky-io-subscription-products' component={StickyIoSubscriptionProductList} allow={[ROLE_ADMIN]} />
                                        <PrivateRoute exact path='/admin/super-admin-tools/sticky-io-lookup' component={SuperAdminToolsStickyIoLookupPage} allow={ALLOW_SUPPORT_ADMIN} />
                                        <PrivateRoute exact path='/notification/:page/:promotionId?' component={Notification} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                        <PrivateRoute exact path='/user-notification/:page' component={Notification} allow={[ROLE_USER, ROLE_PROXY_USER]} />
                                        <PrivateRoute exact path='/purchase-notification' component={NotificationRedirectPage} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                        <PrivateRoute exact path='/admin/banner-image-text' component={BannerImageTextSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/website' component={WebsiteSettingsPage} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/theme' component={ThemeSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/migrate-subscription' component={MigrateSubscriptionsSettings} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/minimum-and-maximum-amounts' component={MinimumAndMaximumAmounts} allow={ROLE_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/age-modal' component={AgeModal} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/contact-us-settings' component={ContactUsSettings} allow={ROLE_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/login-and-signup-title' component={LoginSignupTitle} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/miscellaneous-pop-up' component={MiscellaneousPopUp} allow={ROLE_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/shop' component={Shop} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/payment-setting' component={HybridPaymentSettings} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/custom-menu-links' component={CustomMenuLinksSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/help' component={InfluencerHelp} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                        <PrivateRoute exact path='/help/:page/:id?' component={InfluencerHelpDetailPage} allow={ALLOW_MODEL_CONTENT_MANAGER} />
                                        <PrivateRoute exact path='/user-help' component={InfluencerHelp} allow={[ROLE_USER, ROLE_PROXY_USER]} />
                                        <PrivateRoute exact path='/user-help/:page/:id?' component={InfluencerHelpDetailPage} allow={[ROLE_USER, ROLE_PROXY_USER]} />
                                        <PrivateRoute exact path='/admin/settings/miscellaneous-settings' component={MiscellaneousSettings} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/csv-download' component={CSVDownload} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/recaptcha-setting' component={RecaptchaSettings} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/unlocked-content-user-list' component={unlockedContentUserList} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/health-info' component={HealthInfo} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/timestamp-visibility' component={TimestampVisibility} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/universal-block' component={UniversalBlock} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/universal-block-logs' component={universalBlockLogs} allow={[ROLE_ADMIN]} />
                                        <PrivateRoute exact path='/admin/settings/help-setting' component={helpSettings} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/public-post-setting' component={publicPostSettings} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/user-utm-params' component={userUtmParams} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/cancellation' component={Cancellation} allow={ALLOW_MODEL_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/subscription-cancellation-reason' component={CancellationReasonSettings} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/redirect-url/:route?' component={redirectUrl} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/list-redirect-url' component={redirectUrlList} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/forum-pay-setting' component={ForumPaySettings} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/comments' component={blogCommentSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/hide-earnings' component={HideEarning} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/feed-tags' component={FeedTags} allow={ALLOW_ALL_ADMINS} />
                                        <PrivateRoute exact path='/admin/voids-refund-and-chargeback' component={VoidRefundAndChargeback} allow={ALLOW_ADMIN} />
                                        <Route exact path='/feed/:blog_id/:from_notification?' component={blogInfo} />
                                        <PrivateRoute exact path='/profile/wallet' component={Wallet} allow={ALLOW_ONLY_USER} />
                                        <PrivateRoute exact path='/admin/settings/announcement-banner' component={AnnouncementBanner} allow={ALLOW_MODEL_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/show-payment-icons' component={ShowPaymentIcons} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/chat-settings' component={ChatListSettings} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/user-purchase-history' component={UserPurchaseHistory} allow={ALLOW_SUPPORT_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/show-media-count-in-exclusive-content' component={ShowMediaCountInExclusiveContentSettings} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/influencer-activity' component={influencerActivity} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/manage-mass-messages' component={ManageMassMessages} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/navbar' component={Navbar} allow={ALLOW_MODEL_ADMIN} />
                                        <PrivateRoute exact path='/admin/reporting' component={ReportingMenu} allow={ALLOW_ALL_ADMINS} />
                                        <PrivateRoute exact path='/admin/settings/web-push-notification' component={WebPushNotificationSettings} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/universal-login' component={UniversalLoginSetting} allow={ALLOW_ONLY_SUPER_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/coupon' component={CouponSettings} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/coupon-reports' component={CouponReports} allow={ALLOW_ALL_ADMINS} />
                                        <PrivateRoute exact path='/admin/coupon-reports/coupon-details/:couponId' component={CouponDetails} allow={ALLOW_ALL_ADMINS} />
                                        <Route exact path='/more-links' component={CustomMenuLinks} />
                                        <Route exact path='/content-removal' component={ContentRemoval} />
                                        <Route exact path='/:path' component={RedirectRouteInformation} />
                                        <PrivateRoute exact path='/admin/settings/subscription-email-notification' component={SubscriptionEmailNotification} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/settings/display-pagination-on-exclusive-content' component={DisplayPaginationOnExclusiveContent} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/edit-content-removal' component={EditContentRemoval} allow={ALLOW_ADMIN} />
                                        <PrivateRoute exact path='/admin/content-manager' component={MediaManager} allow={[ROLE_MODEL, ROLE_ADMIN, ROLE_CONTENT_MANAGER, ROLE_SUB_ADMIN]} />
                                        <PrivateRoute exact path='/admin/settings/redis-cache' component={EnableRedisCache} allow={ALLOW_ADMIN} />
                                        <Route component={Error404} />
                                    </Switch>
                                </GroupLiveStreamWrapper>
                            </React.Suspense>
                        </GoogleAnalytics>
                    </Router>
                </Startup>
            </Provider>
        )
    }
}

export default App
