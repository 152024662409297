import React, { useCallback, useEffect, useRef, useState } from 'react'
import Janus, { publishToRoom, unPublishOwnFeed, publishOwnFeed } from 'janus-js-core'
import socket from '../../utils/socket'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import GroupLiveStreamModal from './GroupLiveStreamModal'
import { startStream, stopStream, removeGroupStreamData, setStreamLoader, setLiveStreamStatus, resetLiveStreamState, setIsLive } from '../../actions/streamAction'
import { showBootstrapNavbar } from '../../actions/chatActions'
import { getAppBaseUrl } from '../../actions/api'
import LiveStreamModal from './LiveStreamModal'

const BASE_URL = getAppBaseUrl()

const StreamPublisher = (props) => {

    const $video = useRef(null) // ref to video element for media stream

    const [playerStatus, setPlayerStatus] = useState('ready')
    const [modalIsOpen, setIsOpen] = React.useState(false)
    const [isPublisherMuted, setPublisherIsMuted] = useState(false)
    const [sfutest, setSfuTest] = useState(null)
    const sfuTestRef = useRef(sfutest)
    const { id } = props.match.params
    const [activeDeviceId, setActiveDeviceId] = useState('')
    const [changedDeviceId, setChangedDeviceId] = useState('')
    const [numberOfVideoDevices, setNumberOfVideoDevices] = useState(0)
    const [videoInputList, setVideoInputList] = useState([])
    const [audioInputList, setAudioInputList] = useState([])
    const [isLiveStreamStarted, setIsLiveStreamStarted] = useState(false)

    const getStreamRecordName = (id) => {
        return `${BASE_URL.replace('://', '_')}_LIVE_STREAM_REC_${id}`
    }

    useEffect(() => {
        if (props.isGroupLive) {
            socket.on('STOP_CAMERA_AUDIO', () => {
                unPublishOwnFeed(sfuTestRef.current)
            })
        }
    }, [])

    useEffect(() => {
        sfuTestRef.current = sfutest
    }, [sfutest])

    const getCameraDetails = () => {
        const config = { audio: true, video: true }
        navigator.mediaDevices
            .getUserMedia(config)
            .then(function () {
                navigator.mediaDevices.enumerateDevices().then(function (devices) {
                    const videoDevices = devices.filter(device => device.kind === 'videoinput')
                    const audioDevices = devices.filter(device => device.kind === 'audioinput')

                    if (videoDevices.length > 1) {
                        setActiveDeviceId(videoDevices[0].deviceId)
                        setChangedDeviceId(videoDevices[1].deviceId)
                        setNumberOfVideoDevices(videoDevices.length)
                    }

                    setVideoInputList(videoDevices)
                    setAudioInputList(audioDevices)
                })
            })
    }

    useEffect(() => {
        const { role } = props.auth.user
        if (props.isGroupLive === true && playerStatus === 'live' && (role === 'model' || role === 'live_stream_manager')) {
            socket.emit('GROUP_LIVE_STARTED', props.tabToken)
        }
        if (!props.isGroupLive) {
            props.setLiveStreamStatus(playerStatus)
        }
    }, [playerStatus])

    const handleStartClick = useCallback(() => {
        if (sfutest) {
            const data = {
                userId: id
            }
            if (props.isGroupLive === true) {
                data.isGroupLiveStream = true
                props.setIsLoading(false)
            }
            if (isLiveStreamStarted === false) {
                setIsLiveStreamStarted(true)
                props.startStream(data, (res) => {
                    console.log('model started Go Live stream')
                    props.onGoLive()
                    if (props.isGroupLive !== true) {
                        const userData = {
                            userId: id,
                            tabToken: props.tabToken
                        }
                        socket.emit('START_LIVE_STREAM', userData)
                    }
                    const filename = getStreamRecordName(res.data._id)
                    publishOwnFeed({ sfutest, useAudio: true, record: true, filename: filename })
                    setIsOpen(true)
                    props.setStreamLoader(false)
                    getCameraDetails()
                })
            }
        }
    })

    useEffect(() => {
        if (sfutest !== null && props.isGroupLive === true) {
            handleStartClick()
        }
    }, [sfutest])

    const handleStopClick = () => {
        if (window.confirm('Are you sure that you want to end the Go Live session?')) {
            if (sfutest) {
                console.log('model ended Go Live stream')
                setIsLiveStreamStarted(false)
                props.onStopLive()
                props.setIsLive(false)
                unPublishOwnFeed(sfutest)
                setIsOpen(false)
                if (props.isGroupLive === true) {
                    props.removeGroupStreamData()
                    socket.emit('STOP_GROUP_LIVE_STREAM')
                } else {
                    socket.emit('STOP_LIVE_STREAM', id)
                }
                props.setGoLiveLoader(true)
                props.stopStream()
                if (props.isGroupLive === true) {
                    goToPreviousPath()
                }
            }
        }
    }

    const handleMuteClick = () => {
        if (!sfutest.isAudioMuted()) {
            sfutest.muteAudio()
        }
        setPublisherIsMuted(true)
    }

    const handleUnMuteClick = () => {
        if (sfutest.isAudioMuted()) {
            sfutest.unmuteAudio()
        }
        setPublisherIsMuted(false)
    }

    // const handleBandwidthChange = (bitrate) => {
    //     sfutest.send({ message: { request: 'configure', bitrate } })
    // }

    const history = props.history
    const goToPreviousPath = () => {
        history.goBack()
    }

    const handlePublishToRoomCallback = useCallback(
        (_sfutest, eventType, data) => {
            setSfuTest(_sfutest)
            switch (eventType) {
                case 'created': {
                    props.setRoom(data.room)
                    break
                }
                case 'joined': {
                    const { id, private_id } = data
                    props.setPubId(id)
                    props.setPubPvtId(private_id)
                    setPlayerStatus('stop')

                    break
                }
                case 'onlocalstream': {
                    Janus.attachMediaStream($video.current, data)
                    if (
                        _sfutest.webrtcStuff.pc.iceConnectionState !== 'completed' &&
                        _sfutest.webrtcStuff.pc.iceConnectionState !== 'connected'
                    ) {
                        setPlayerStatus('live')
                    }

                    break
                }
                case 'oncleanup': {
                    setPlayerStatus('stop')
                    // use in production mode, will cause problem when publisher and viewer in one page like in demo
                    // Janus.destroy()
                    // setPublisherIsMuted(false)
                    props.resetLiveStreamState()
                    setIsLiveStreamStarted(false)
                    break
                }
                case 'error': {
                    setPlayerStatus('error')
                    // setPublisherIsMuted(false)
                    break
                }
                default:
                    break
            }
        }, [props.setPubId, props.setRoom, props.setPubPvtId]
    )

    useEffect(() => {
        if (props.janusInstance) {
            // creating videoroom
            publishToRoom({
                janus: props.janusInstance,
                opaqueId: 'user.id',
                room: undefined,
                secret: 'user.id',
                pin: undefined,
                username: 'user.nickname',
                isPublisher: true,
                callback: handlePublishToRoomCallback,
                rec_dir: '/recordings'
            })
            // handleStartClick()
        }
    }, [handlePublishToRoomCallback, props.janusInstance])

    const { content_color, content_font_color } = props.auth.appSettings

    const onSwitchCamera = (videoDeviceId, audioDeviceId = '') => {
        let isAudioEnabled = !isPublisherMuted
        let body = { audio: isAudioEnabled, video: true }
        sfutest.createOffer(
            {
                // We provide a specific device ID for both audio and video
                media: {
                    audio: {
                        deviceId: audioDeviceId ? { exact: audioDeviceId } : undefined
                    },
                    video: {
                        deviceId: typeof videoDeviceId === 'string' ? (videoDeviceId ? { exact: videoDeviceId } : undefined) : (changedDeviceId ? { exact: changedDeviceId } : undefined)
                    },
                    replaceAudio: isAudioEnabled,
                    replaceVideo: true,	// This is only needed in case of a renegotiation
                    data: true	// Let's negotiate data channels as well
                },
                simulcast: false,
                simulcast2: false,
                // If you want to test simulcasting (Chrome and Firefox only), then
                // pass a ?simulcast=true when opening this demo page: it will turn
                // the following 'simulcast' property to pass to janus.js to true
                success: function (jsep) {
                    Janus.debug('Got SDP!', jsep)
                    sfutest.send({ message: body, jsep: jsep })
                    setActiveDeviceId(changedDeviceId)
                    setChangedDeviceId(activeDeviceId)
                },
                error: function (error) {
                    alert('WebRTC error')
                    Janus.error('WebRTC error:', error)
                }
            })
    }

    useEffect(() => {
        if (props.isGroupLive !== true && !props.loader && playerStatus !== 'live' && isLiveStreamStarted === false) {
            handleStartClick()
        }
    }, [props.loader])

    return (
        <>
            {props.isGroupLive !== true &&
                <>
                    {playerStatus === 'live' &&
                        <button onClick={handleStopClick} className='btn shadow-none' style={{ backgroundColor: content_color, color: content_font_color }}>END LIVE</button>
                    }
                </>
            }

            {/* <StreamControls
                        status={playerStatus}
                        onBandwidthChange={handleBandwidthChange}
                        onStop={handleStopClick}
                        onMute={handleMuteClick}
                        onUnmute={handleUnMuteClick}
                        isMuted={isMuted}
                        onStart={handleStartClick}
                    /> */}
            {
                modalIsOpen === true && props.isGroupLive === true &&
                <GroupLiveStreamModal
                    video={$video}
                    handleStopClick={handleStopClick}
                    isPublisher={true}
                    isPublisherMuted={isPublisherMuted}
                    onMute={handleMuteClick}
                    onUnmute={handleUnMuteClick}
                    onSwitchCamera={onSwitchCamera}
                    numberOfVideoDevices={numberOfVideoDevices}
                    audioInputList={audioInputList}
                    videoInputList={videoInputList}
                    isMobileDevice={window.screen.width < 576 ? true : false}
                />
            }
            {
                modalIsOpen === true && props.isGroupLive !== true &&
                <LiveStreamModal
                    video={$video}
                    handleStopClick={handleStopClick}
                    isPublisher={true}
                    isPublisherMuted={isPublisherMuted}
                    onMute={handleMuteClick}
                    onUnmute={handleUnMuteClick}
                    onSwitchCamera={onSwitchCamera}
                    numberOfVideoDevices={numberOfVideoDevices}
                    audioInputList={audioInputList}
                    videoInputList={videoInputList}
                    isMobileDevice={window.screen.width < 576 ? true : false}
                />
            }
        </>
    )
}

StreamPublisher.propTypes = {
    setPubId: PropTypes.func.isRequired,
    setRoom: PropTypes.func.isRequired,
    setPubPvtId: PropTypes.func.isRequired,
    janusInstance: PropTypes.object,
    onGoLive: PropTypes.func.isRequired,
    onStopLive: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    startStream: PropTypes.func.isRequired,
    tabToken: PropTypes.string.isRequired,
    loader: PropTypes.bool.isRequired,
    stopStream: PropTypes.func.isRequired,
    isGroupLive: PropTypes.bool,
    removeGroupStreamData: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    showBootstrapNavbar: PropTypes.func.isRequired,
    setStreamLoader: PropTypes.func.isRequired,
    setLiveStreamStatus: PropTypes.func.isRequired,
    setGoLiveLoader: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
    resetLiveStreamState: PropTypes.func.isRequired,
    liveStream: PropTypes.object.isRequired,
    setIsLive: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    liveStream: state.liveStream
})

export default connect(mapStateToProps, {
    startStream,
    stopStream,
    removeGroupStreamData,
    showBootstrapNavbar,
    setStreamLoader,
    setLiveStreamStatus,
    resetLiveStreamState,
    setIsLive
})(withRouter((StreamPublisher)))
