import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import { tipFromChat } from '../../actions/ccbillActions'
import { sendGroupStreamMessage, addGroupMessageInEnd, sendTipsByAdmin } from '../../actions/streamAction'
import { addNewMessageInEnd } from '../../actions/chatActions'
import socket from '../../utils/socket'
import _ from 'lodash'
import { stickyIoTipFromChat } from '../../actions/stickyIoActions'
import { TipPayment } from '../../actions/hybridPaymentAction'
import PaymentForm from '../payment/PaymentForm'
import CryptoForm from '../crypto/CryptoForm'
import { purchaseFromWallet, updateWalletAmount } from '../../actions/cryptoPaymentActions'
import AddFundForm from '../crypto/AddFundForm'
import ConfirmSweetAlert from '../Layouts/ConfirmSweetAlert'
import { setConfirmSweetAlert, setSweetAlert } from '../../actions/sweetAlertActions'
import SweetAlertsWrapper from '../Layouts/SweetAlertsWrapper'
import SendRoundedIcon from '@material-ui/icons/SendRounded'

const InputButton = styled.button`
    background-color: ${props => props.buttonFontColor};
    color: ${props => props.buttonColor};
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50% !important;

    &:hover {
        background-color: ${props => props.buttonFontColor};
        color: ${props => props.buttonColor};
    }
`

const SendButtonDiv = styled.div`
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
`

const InputTextFieldDiv = styled.div`
    padding-right: 0 !important;
`

const SubmitButton = styled.button`
    border: none;
    background-color: #0000004d;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;

    ::focus{
        outline: none;
        box-shadow: none;
        border: none;
    }

    &:hover {
        background-color: #0000004d;
        color: white;
    }
`

function ChatInputOnGroupLive(props) {
    const {
        content_color,
        content_font_color,
        card_background_color,
        site_font_color,
        tips_minimum_amount,
        tips_maximum_amount,
        model_id
    } = props.auth.appSettings
    const { isAdmin, role } = props.auth.user
    const { isLoading } = props.ccbill

    const [openInput, setOpenInput] = useState(false)
    const [amount, setAmount] = useState(5)
    const [messageText, setMessageText] = useState('')
    const [showAlert, setShowAlert] = useState(true)
    const [isAnonymousTip, setIsAnonymousTip] = useState(false)
    const [modelName, setModelName] = useState('')
    const [disableSendButton, setDisableSendButton] = useState(false)
    const [isTipByCrypto, setIsTipByCrypto] = useState(false)
    const [showAddFundPopup, setShowAddFundPopup] = useState(false)
    const [remainAmount, setRemainAmount] = useState(0)
    const [showConfirmAlert, setConfirmAlert] = useState(false)

    useEffect(() => {
        socket.on('GROUP_LIVE_STOPPED', () => {
            socket.emit('LEAVE_GROUP_STREAM')
            goToPreviousPath()
        })
        if (props.chat.userProfileInfo[model_id] !== undefined) {
            setModelName(props.chat.userProfileInfo[model_id].name)
        }
    }, [])

    const goToPreviousPath = () => {
        const prevURL = _.get(props.history, 'location.state.from', false)
        if (prevURL !== false && prevURL !== '/group-live-stream') {
            props.history.push(prevURL)
        } else {
            props.history.push('/')
        }
    }

    const InputModelOpen = () => {
        setOpenInput(true)
    }

    const InputModelClose = () => {
        if (isLoading === false) {
            setOpenInput(false)
        }
    }

    const sendTip = async () => {
        let amountValue = amount
        let amountInt = parseInt(amountValue, 10)
        let tipsMinimumAmount = parseInt(tips_minimum_amount, 10)
        let tipsMaximumAmount = parseInt(tips_maximum_amount, 10)

        if (!((tipsMinimumAmount <= amountInt) && (amountInt <= tipsMaximumAmount))) {
            props.setSweetAlert({ description: `Choose a tip between ${tipsMinimumAmount} and ${tipsMaximumAmount}` })
            setAmount(tipsMinimumAmount)
        } else {
            if (!/^[0-9]+(\.[0-9]{1,2})$/.test(amountValue)) {
                amountValue = parseFloat(amountValue).toFixed(2)
            }

            let confirmMessage = `Please Confirm Tip of $${amountValue}.`
            if (props.auth.user.default_payment_method === 'crypto_currency') {
                confirmMessage += `You currently have $${props.auth.user.wallet_amount} in your wallet. Amount will be debited from your wallet balance.`
            }

            setAmount(amountValue)
            if (props.auth.user.default_payment_method === 'crypto_currency' && props.auth.user.wallet_amount < parseFloat(amount)) {
                processTipPayment()
            } else {
                setConfirmAlert(true)
                props.setConfirmSweetAlert({
                    description: confirmMessage
                })
            }
        }
    }

    const processTipPayment = async () => {
        let data = {
            amount: amount,
            recurring: false,
            email: props.auth.user.email,
            action: 'tips',
            tipFrom: 'group_go_live_stream',
            isAnonymousTip
        }
        if (isAdmin) {
            props.sendTipsByAdmin(data, (res) => {
                setOpenInput(false)
                if (res && res.data.error) {
                    props.setSweetAlert({ description: res.data.error })
                }
            })
        } else {
            if (props.auth.user.default_payment_method === 'crypto_currency') {
                tipUsingCrypto(props.auth.user.wallet_amount)
            } else {
                const payment_api_version = _.get(props.auth.appSettings, 'payment_api_version', 'v1')
                if (payment_api_version === 'v1') {
                    if (props.auth.appSettings.is_sticky_io_enabled === true) {
                        props.stickyIoTipFromChat(data, props.auth.user._id, (res) => {
                            if (res.data.success === 0) {
                                props.setSweetAlert({ description: res.data.message })
                                setOpenInput(false)
                            } else {
                                setOpenInput(false)
                            }
                            setAmount(tips_minimum_amount)
                            setIsAnonymousTip(false)
                        })
                    } else {
                        props.tipFromChat(data, props.auth.user._id, (res) => {
                            if (res.data.error) {
                                props.setSweetAlert({ description: res.data.error })
                                setOpenInput(false)
                            } else {
                                setOpenInput(false)
                            }
                            setAmount(tips_minimum_amount)
                            setIsAnonymousTip(false)
                        })
                    }
                } else {
                    await props.TipPayment(data, props.auth.user._id)
                    setOpenInput(false)
                    setAmount(tips_minimum_amount)
                    setIsAnonymousTip(false)
                }
            }
        }
    }

    const tipUsingCrypto = async (walletBalance) => {
        const tipAmount = parseFloat(amount).toFixed(2)
        if (walletBalance >= tipAmount) {
            const data = {
                payment_for: 'tips',
                amount: tipAmount.toString(),
                tip_from: 'group_go_live_stream',
                is_anonymous_tip: isAnonymousTip
            }
            const res = await props.purchaseFromWallet(data)
            if (res.success === 1) {
                props.updateWalletAmount(res.data.wallet_balance)
                setOpenInput(false)
                props.setSweetAlert({ description: res.data.message })
            } else {
                const message = _.get(res, 'message', 'something went wrong')
                setOpenInput(false)
                props.setSweetAlert({ description: message })
            }
            setAmount(tips_minimum_amount)
            setIsAnonymousTip(false)
        } else {
            const remainAmount = Math.ceil(tipAmount - walletBalance)
            if (walletBalance === 0.00) {
                setShowAddFundPopup(true)
                setRemainAmount(0)
            } else {
                setShowAddFundPopup(true)
                setRemainAmount(remainAmount)
            }
        }
    }

    const onTextMessageSend = (e) => {
        e.preventDefault()
        if (messageText.trim() === '') {
            return props.setSweetAlert({ description: 'Please type message.' })
        }
        if (messageText.trim().length > 500) {
            return props.setSweetAlert({ description: 'Maximum 500 characters allowed.' })
        }
        setDisableSendButton(true)
        const messageObject = {
            type: 'text',
            fromAdmin: role === 'model' ? true : false,
            message: messageText,
            senderId: props.auth.user._id,
            message_from_admin: isAdmin
        }
        props.sendGroupStreamMessage(messageObject, (res) => {
            if (res.status === 401) {
                setMessageText('')
                setDisableSendButton(false)
                return props.setSweetAlert({ description: res.statusText })
            }
        })
        setMessageText('')
        setDisableSendButton(false)
    }

    const onClickAlert = () => {
        setShowAlert(false)
        props.startVideoOnAlert()
    }

    const hideAddFundForm = () => {
        setShowAddFundPopup(false)
        setOpenInput(false)
    }

    return (
        <>
            <div className='row align-items-center justify-content-center mt-1'>
                <div className='col-12 col-md-auto w-100 px-3'>
                    <div className='row px-3'>
                        {role === 'model' ?
                            <div className='col-12 col-sm-12 col-md'>
                                <form autoComplete='off' className='my-3'
                                    onSubmit={onTextMessageSend}
                                >
                                    <div className='row align-items-center justify-content-center'>
                                        <InputTextFieldDiv className='col'>
                                            <input
                                                className={'form-control'}
                                                type='text'
                                                onChange={(e) => setMessageText(e.target.value)}
                                                value={messageText}
                                                style={{ backgroundColor: 'transparent', border: '1px solid #ffffff85', color: '#fff' }}
                                                placeholder='Write a message...'
                                            />
                                        </InputTextFieldDiv>
                                        <SendButtonDiv className='col-auto'>
                                            <SubmitButton type='submit' disabled={messageText === '' || disableSendButton ? true : false}>
                                                <SendRoundedIcon style={{ color: 'white' }} />
                                            </SubmitButton>
                                        </SendButtonDiv>
                                    </div>
                                </form>
                            </div> :
                            <>
                                <div className='col-12 px-0'>
                                    <div className='input-group mb-3'>
                                        <div className='input-group-prepend'>
                                            <span className='pl-2' id='inputGroup-sizing-default'>
                                                <InputButton
                                                    className='btn my-2'
                                                    buttonFontColor='#0000004d'
                                                    buttonColor='white'
                                                    onClick={InputModelOpen}
                                                >
                                                    <i className="fas fa-dollar-sign"></i>
                                                </InputButton>
                                            </span>
                                        </div>
                                        <form autoComplete='off' className='my-3' style={{ display: 'flex', width: 'calc(100% - 67px)' }}
                                            onSubmit={onTextMessageSend}
                                        >
                                            <InputTextFieldDiv className='col'>
                                                <input
                                                    className={'form-control'}
                                                    type='text'
                                                    onChange={(e) => setMessageText(e.target.value)}
                                                    value={messageText}
                                                    style={{ backgroundColor: 'transparent', border: '1px solid #ffffff85', color: '#fff' }}
                                                    placeholder='Write a message...'
                                                />
                                            </InputTextFieldDiv>
                                            <div className='input-group-append'>
                                                <span className=''>
                                                    <SendButtonDiv className='col-auto'>
                                                        <SubmitButton type='submit' disabled={messageText === '' || disableSendButton ? true : false}>
                                                            <SendRoundedIcon style={{ color: 'white' }} />
                                                        </SubmitButton>
                                                    </SendButtonDiv>
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            {
                openInput === true ?
                    <WebsiteFontColor>
                        <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                            <div className='modal-dialog modal-dialog-centered' role='document'>
                                <div className='modal-content'>
                                    <div className='modal-header' style={{ backgroundColor: card_background_color }}>
                                        <h5 className='modal-title'>Send Tip</h5>
                                        <button className='close' onClick={InputModelClose} style={{ color: site_font_color, opacity: 1 }} disabled={isLoading} />
                                    </div>
                                    <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                        <div className='container'>
                                            <div className='form-row'>
                                                <div className='form-group col'>
                                                    <div className='input-group'>
                                                        <div className='input-group-prepend'>
                                                            <span className='input-group-text form-control'>$</span>
                                                        </div>
                                                        <input
                                                            type='number'
                                                            id='amount'
                                                            name='amount'
                                                            className='form-control'
                                                            value={amount}
                                                            onChange={(e) => setAmount(e.target.value)}
                                                            min={tips_minimum_amount}
                                                            max={tips_maximum_amount}
                                                            pattern='[0-9].[0-9]{2}'
                                                            disabled={isLoading}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='input-group py-3'>
                                                        <input
                                                            className='mt-1'
                                                            type='checkbox'
                                                            id='anonymous-tip'
                                                            checked={isAnonymousTip}
                                                            onChange={(e) => setIsAnonymousTip(e.target.checked)}
                                                            disabled={isLoading}
                                                        />
                                                        <label className='form-check-label ml-3' htmlFor='anonymous-tip'>
                                                            I want to hide my identity from other users.
                                                        </label>
                                                    </div>
                                                    <small id='navbar-font-weight-help' className='form-text text-muted'>
                                                        Minimum ${tips_minimum_amount} USD.
                                                        <br />
                                                        {props.auth.user.default_payment_method === 'crypto_currency' ?
                                                            <>
                                                                ${amount} will be debited from wallet balance.
                                                            </>
                                                            :
                                                            <>
                                                                ${amount} will be charged to the payment method you used previously on the website.
                                                            </>
                                                        }
                                                    </small>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <button type='button' className='btn btn-block'
                                                        onClick={sendTip}
                                                        style={{ width: '100%', backgroundColor: content_color, color: content_font_color }}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading === false ? `Send a tip of $${amount}` : 'Payment processing'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WebsiteFontColor>
                    : null
            }
            {
                showAlert === true && role !== 'model' ?
                    <WebsiteFontColor>
                        <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                            <div className='modal-dialog modal-dialog-centered' role='document'>
                                <div className='modal-content'>
                                    <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                        <div className='container'>
                                            <div className='row'>
                                                <div className='col-12 text-center'>
                                                    <p>{modelName} has started the Go Live Stream.  Be sure to turn on the volume using the sound icon at the top of the screen when you are ready.</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <button type='button' className='btn btn-block'
                                                        onClick={onClickAlert}
                                                        style={{ width: '100%', backgroundColor: content_color, color: content_font_color }}
                                                    >
                                                        Ok
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WebsiteFontColor>
                    : null
            }
            {props.utility.showPaymentModel === true &&
                <PaymentForm
                    addCardAndSubscription={false}
                />
            }
            {isTipByCrypto === true &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                        <div className='modal-content' style={{ backgroundColor: card_background_color }}>
                            <div className='modal-header align-items-center'>
                                <button className='close' onClick={() => {
                                    setIsTipByCrypto(false)
                                }} style={{ color: site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body'>
                                <div className='container'>
                                    <CryptoForm
                                        onHideAddFundsForm={props.onHideAddFund}
                                        type='tips'
                                        tipFrom='group_go_live_stream'
                                        isAnonymousTip={isAnonymousTip.toString()}
                                        amount={amount.toString()}
                                        onCompleteTransaction={() => {
                                            setIsTipByCrypto(false)
                                            setOpenInput(false)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showAddFundPopup === true &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div id='add-fund-form' className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                        <div className='modal-content' style={{ backgroundColor: card_background_color }}>
                            <div className='modal-header align-items-center'>
                                <button className='close' onClick={() => {
                                    setShowAddFundPopup(false)
                                    setRemainAmount(0)
                                }} style={{ color: site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body'>
                                <div className='container'>
                                    <AddFundForm
                                        onHideAddFund={hideAddFundForm}
                                        type='tips'
                                        requestFrom='live-stream'
                                        transactionAmount={Number(amount)}
                                        remainAmount={remainAmount}
                                        onCompleteTransaction={
                                            (updatedBalance) => {
                                                setShowAddFundPopup(false)
                                                if (updatedBalance) {
                                                    tipUsingCrypto(updatedBalance)
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showConfirmAlert && <ConfirmSweetAlert onConfirm={() => { processTipPayment(); setConfirmAlert(false) }} onCancel={() => setConfirmAlert(false)} />}
            <SweetAlertsWrapper />
        </>
    )
}

ChatInputOnGroupLive.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    tipFromChat: PropTypes.func.isRequired,
    addGroupMessageInEnd: PropTypes.func.isRequired,
    sendGroupStreamMessage: PropTypes.func.isRequired,
    startVideoOnAlert: PropTypes.func.isRequired,
    ccbill: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    sendTipsByAdmin: PropTypes.func.isRequired,
    stickyIoTipFromChat: PropTypes.func.isRequired,
    TipPayment: PropTypes.func.isRequired,
    utility: PropTypes.object,
    purchaseFromWallet: PropTypes.func.isRequired,
    updateWalletAmount: PropTypes.func.isRequired,
    onHideAddFund: PropTypes.func.isRequired,
    setConfirmSweetAlert: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    ccbill: state.ccbill,
    chat: state.chat,
    utility: state.utility
})

export default connect(mapStateToProps, {
    tipFromChat,
    sendTipsByAdmin,
    addGroupMessageInEnd,
    sendGroupStreamMessage,
    addNewMessageInEnd,
    stickyIoTipFromChat,
    TipPayment,
    purchaseFromWallet,
    updateWalletAmount,
    setConfirmSweetAlert,
    setSweetAlert
})(withRouter(ChatInputOnGroupLive))
