import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

function GroupLiveNotificationBar(props) {
    const { content_color, content_font_color, model_id, site_font_color } = props.auth.appSettings
    const [modelName, setModelName] = useState('')

    const joinStream = () => {
        props.history.push({
            pathname: '/group-live-stream', state: {
                from: props.location.pathname
            }
        })
    }
    useEffect(() => {
        if (props.chat.userProfileInfo[model_id] !== undefined) {
            setModelName(props.chat.userProfileInfo[model_id].name)
        }
    }, [])
    return (
        <>
            {props.liveStream.isGroupLiveStreamStarted === true  && (!props.chat.isChatScreenOpen || window.location.pathname !== '/private-chat' || window.innerWidth > 768) &&
                <div style={{ borderBottom: `1px solid ${site_font_color}` }}>
                    {modelName} is now live!
                    <button
                        className='btn btn-sm'
                        style={{ backgroundColor: content_color, color: content_font_color, fontSize: '11px' }}
                        onClick={joinStream}
                    >
                        Join Stream
                    </button>
                </div >
            }
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    liveStream: state.liveStream
})

GroupLiveNotificationBar.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    liveStream: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

export default connect(mapStateToProps, {})(withRouter(GroupLiveNotificationBar))
