export const GET_ERRORS = 'GET_ERRORS'
export const USER_LOADING = 'USER_LOADING'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const GET_BLOG_DATA = 'GET_BLOG_DATA'
export const ADD_NEW_BLOG = 'ADD_NEW_BLOG'
export const RESET_CURRENT_PAGE = 'RESET_CURRENT_PAGE'
export const GET_ADMIN_USER = 'GET_ADMIN_USER'
export const GET_SUBSCRIBERS = 'GET_SUBSCRIBERS'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const ADD_NEW_PRODUCT = 'ADD_NEW_PRODUCT'
export const GET_CART = 'GET_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const ADD_TO_CART = 'ADD_TO_CART'
export const EMPTY_CART = 'EMPTY_CART'
export const LOAD_APP_SETTINGS = 'LOAD_APP_SETTINGS'
export const IS_LOADING = 'IS_LOADING'
export const PAYMENT_PROCESSING = 'PAYMENT_PROCESSING'
export const PAYMENT_COMPLETED = 'PAYMENT_COMPLETED'
export const CHAT_USER_LIST = 'CHAT_USER_LIST'
export const SET_MESSAGES = 'SET_MESSAGES'
export const PROFILE_PHOTO_UPLOADING = 'PROFILE_PHOTO_UPLOADING'
export const CHAT_USER_PROFILE_INFO = 'CHAT_USER_PROFILE_INFO'
export const SET_ALL_USERS = 'SET_ALL_USERS'
export const SET_TRANSACTION_AMOUNT = 'SET_TRANSACTION_AMOUNT'
export const SET_ALL_TRANSACTION_AMOUNT = 'SET_ALL_TRANSACTION_AMOUNT'
export const SET_ALL_USERS_TRANSACTIONS = 'SET_ALL_USERS_TRANSACTIONS'
export const IS_BLUR_LOADING = 'IS_BLUR_LOADING'
export const STOP_IS_BLUR_LOADING = 'STOP_IS_BLUR_LOADING'
export const SET_IS_PROFILE_READY = 'SET_IS_PROFILE_READY'
export const SET_IS_USER_LIST_LOADING = 'SET_IS_USER_LIST_LOADING'
export const UPDATE_BLOG_DATA = 'UPDATE_BLOG_DATA'
export const BLOG_UNLOCK_PAYMENT_PROCESSING = 'BLOG_UNLOCK_PAYMENT_PROCESSING'
export const BLOG_UNLOCK_PAYMENT_COMPLETED = 'BLOG_UNLOCK_PAYMENT_COMPLETED'
export const PHOTO_ID_UPLOADING = 'PHOTO_ID_UPLOADING'
export const PHOTO_ID_UPLOADED = 'PHOTO_ID_UPLOADED'
export const OPEN_PHOTO_ID_MODAL = 'OPEN_PHOTO_ID_MODAL'
export const CLOSE_PHOTO_ID_MODAL = 'CLOSE_PHOTO_ID_MODAL'
export const SET_UNREAD_COUNT = 'SET_UNREAD_COUNT'
export const TIPS_LIST = 'TIPS_LIST'
export const IS_PRODUCT_LIST_LOADING = 'IS_PRODUCT_LIST_LOADING'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const IS_MORE_MESSAGE_LOADING = 'IS_MORE_MESSAGE_LOADING'
export const LOAD_MORE_MESSAGE = 'LOAD_MORE_MESSAGE'
export const SET_PRODUCT = 'SET_PRODUCT'
export const IS_PRODUCT_LOADING = 'IS_PRODUCT_LOADING'
export const ADDRESS_LIST = 'ADDRESS_LIST'
export const LOAD_SINGLE_ADDRESS = 'LOAD_SINGLE_ADDRESS'
export const IS_ADDRESS_LIST_LOADING = 'IS_ADDRESS_LIST_LOADING'
export const GET_ORDERS = 'GET_ORDERS'
export const IS_ORDER_LIST_LOADING = 'IS_ORDER_LIST_LOADING'
export const GET_SINGLE_ORDER = 'GET_SINGLE_ORDER'
export const LOAD_THEME_3_SETTINGS = 'LOAD_THEME_3_SETTINGS'
export const SET_CHAT_SELECTED_ID = 'SET_CHAT_SELECTED_ID'
export const SET_CHAT_MODEL_ID = 'SET_CHAT_MODEL_ID'
export const TOGGLE_NEW_PAYMENT_MODAL = 'TOGGLE_NEW_PAYMENT_MODAL'
export const OPEN_COPY_TO_CLIPBOARD_TOAST = 'OPEN_COPY_TO_CLIPBOARD_TOAST'
export const CLOSE_COPY_TO_CLIPBOARD_TOAST = 'CLOSE_COPY_TO_CLIPBOARD_TOAST'
export const GALLERY_ADD_FILE = 'GALLERY_ADD_FILE'
export const GALLERY_REMOVE_FILE = 'GALLERY_REMOVE_FILE'
export const GALLERY_RESET = 'GALLERY_RESET'
export const UPDATE_GALLERY_PROGRESS = 'UPDATE_GALLERY_PROGRESS'
export const UPDATE_GALLERY_INDEX = 'UPDATE_GALLERY_INDEX'
export const TOGGLE_MISCELLANEOUS_POP_UP = 'TOGGLE_MISCELLANEOUS_POP_UP'
export const ADD_NEW_MESSAGE_IN_END = 'ADD_NEW_MESSAGE_IN_END'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const SET_CHAT_TIMER = 'SET_CHAT_TIMER'
export const REMOVE_CHAT_TIMER = 'REMOVE_CHAT_TIMER'
export const SET_SOCKET_CONNECTED = 'SET_SOCKET_CONNECTED'
export const SET_SOCKET_DISCONNECTED = 'SET_SOCKET_DISCONNECTED'
export const ADD_PAY_PER_MESSAGE_CREDIT = 'ADD_PAY_PER_MESSAGE_CREDIT'
export const REMOVE_PAY_PER_MESSAGE_CREDIT = 'REMOVE_PAY_PER_MESSAGE_CREDIT'
export const SET_GROUP_LIVE_STREAM = 'SET_GROUP_LIVE_STREAM'
export const REMOVE_GROUP_LIVE_STREAM = 'REMOVE_GROUP_LIVE_STREAM'
export const ADD_GROUP_MESSAGE = 'ADD_GROUP_MESSAGE'
export const SET_PROMOTION = 'SET_PROMOTION'
export const UPDATE_USER_OPT_IN_DETAIL = 'UPDATE_USER_OPT_IN_DETAIL'
export const UPDATE_USER_OPT_IN_EMAIL_STATUS = 'UPDATE_USER_OPT_IN_EMAIL_STATUS'
export const UPDATE_USER_SUBSCRIPTION_STATUS = 'UPDATE_USER_SUBSCRIPTION_STATUS'
export const SET_RESUBSCRIPTION_OFFER = 'SET_RESUBSCRIPTION_OFFER'
export const SET_SWEET_ALERT = 'SET_SWEET_ALERT'
export const REMOVE_SWEET_ALERT = 'REMOVE_SWEET_ALERT'
export const REMOVE_RESUBSCRIPTION_OFFER = 'REMOVE_RESUBSCRIPTION_OFFER'
export const UNSET_NOTIFICATION_POPUP = 'UNSET_NOTIFICATION_POPUP'
export const TOGGLE_NOTIFICATION_POPUP = 'TOGGLE_NOTIFICATION_POPUP'
export const HIDE_MIGRATION_SUBSCRIPTION_POP = 'HIDE_MIGRATION_SUBSCRIPTION_POP'
export const ADD_CUSTOM_MENU_LINKS = 'ADD_CUSTOM_MENU_LINKS'
export const SET_USER_SUBSCRIPTION_TO_0 = 'SET_USER_SUBSCRIPTION_TO_0'
export const ADD_INFLUENCER_HELP_POPUP = 'ADD_INFLUENCER_HELP_POPUP'
export const TOGGLE_INFLUENCER_HELP_NOTIFICATION_POPUP = 'TOGGLE_INFLUENCER_HELP_NOTIFICATION_POPUP'
export const INFLUENCER_HELP_POPUP_HIDE = 'INFLUENCER_HELP_POPUP_HIDE'
export const ADD_INFLUENCER_HELP_LIST = 'ADD_INFLUENCER_HELP_LIST'
export const ADD_INFLUENCER_HELP_DETAIL = 'ADD_INFLUENCER_HELP_DETAIL'
export const INFLUENCER_HELP_RESUME_API_CALL = 'INFLUENCER_HELP_RESUME_API_CALL'
export const UPDATE_INFLUENCER_HELP_LIST = 'UPDATE_INFLUENCER_HELP_LIST'
export const ADD_INFLUENCER_HELP_LIST_NOTIFICATION = 'ADD_INFLUENCER_HELP_LIST_NOTIFICATION'
export const UPDATE_INFLUENCER_HELP_LIST_NOTIFICATION = 'UPDATE_INFLUENCER_HELP_LIST_NOTIFICATION'
export const SET_HELP_NOTIFICATION_UNREAD_COUNT = 'SET_HELP_NOTIFICATION_UNREAD_COUNT'
export const RESET_HELP_NOTIFICATION = 'RESET_HELP_NOTIFICATION'
export const SET_NOTIFICATION_FILTER = 'SET_NOTIFICATION_FILTER'
export const SET_HELP_TAG_FILTER = 'SET_HELP_TAG_FILTER'
export const SET_HELP_TAGS = 'SET_HELP_TAGS'
export const TOGGLE_PAYMENT_MODAL = 'TOGGLE_PAYMENT_MODAL'
export const DISPLAY_MST_TIME_FOR_NOTIFICATION = 'DISPLAY_MST_TIME_FOR_NOTIFICATION'
export const UPDATE_USER_REBILL_FAILED_STATUS = 'UPDATE_USER_REBILL_FAILED_STATUS'
export const GALLERY_ADD_FILE_IN_PREVIEW = 'GALLERY_ADD_FILE_IN_PREVIEW'
export const GALLERY_REMOVE_FILE_IN_PREVIEW = 'GALLERY_REMOVE_FILE_IN_PREVIEW'
export const PREVIEW_GALLERY_RESET = 'PREVIEW_GALLERY_RESET'
export const SET_IS_PREVIEW_MODE_ENABLE = 'SET_IS_PREVIEW_MODE_ENABLE'
export const SET_BLOG_IN_PROCESSING = 'SET_BLOG_IN_PROCESSING'
export const SET_IS_HELP_ENABLE = 'SET_IS_HELP_ENABLE'
export const SET_IS_PUBLIC_POST_ENABLE = 'SET_IS_PUBLIC_POST_ENABLE'
export const RESET_PROMOTION = 'RESET_PROMOTION'
export const SET_IS_BLOG_COMMENTS_ENABLE = 'SET_IS_BLOG_COMMENTS_ENABLE'
export const UPDATE_FEED_LIKE_COUNT = 'UPDATE_FEED_LIKE_COUNT'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const SET_DEFAULT_PAYMENT_METHOD = 'SET_DEFAULT_PAYMENT_METHOD'
export const SET_WALLET_AMOUNT = 'SET_WALLET_AMOUNT'
export const TOGGLE_CHAT_TIP_POPUP = 'TOGGLE_CHAT_TIP_POPUP'
export const SELECTED_FEED_TAG = 'SELECTED_FEED_TAG'
export const UPDATE_FEED_TAG_SETTINGS = 'UPDATE_FEED_TAG_SETTINGS'
export const UPDATE_FEED_TAG_LIST = 'UPDATE_FEED_TAG_LIST'
export const UPDATE_USER_LAST_SEEN = 'UPDATE_USER_LAST_SEEN'
export const RESET_REBILL_NOTIFICATION_POPUP = 'RESET_REBILL_NOTIFICATION_POPUP'
export const TOGGLE_ANNOUNCEMENT_BANNER = 'TOGGLE_ANNOUNCEMENT_BANNER'
export const UPDATE_USER_AMOUNT_SPENT = 'UPDATE_USER_AMOUNT_SPENT'
export const TOGGLE_CHAT_LIST_POPUP = 'TOGGLE_CHAT_LIST_POPUP'
export const UPDATE_CHAT_LIST_OPTIONS = 'UPDATE_CHAT_LIST_OPTIONS'
export const UPDATE_USER_NICKNAME = 'UPDATE_USER_NICKNAME'
export const SET_ROTATE_IMAGE = 'SET_ROTATE_IMAGE'
export const UPDATE_USER_LIST = 'UPDATE_USER_LIST'
export const GET_USER_LIST_DATA = 'GET_USER_LIST_DATA'
export const ADD_NEW_LIST = 'ADD_NEW_LIST'
export const UPDATE_LIST_NAME = 'UPDATE_LIST_NAME'
export const DELETE_USER_LIST = 'DELETE_USER_LIST'
export const SET_SEARCH_NAME = 'SET_SEARCH_NAME'
export const SET_FEED_TAG = 'SET_FEED_TAG'
export const CLOSE_RESUBSCRIPTION_BANNER = 'CLOSE_RESUBSCRIPTION_BANNER'
export const CLOSE_PROMOTION_BANNER = 'CLOSE_PROMOTION_BANNER'
export const SET_FEED_TAG_LIST = 'SET_FEED_TAG_LIST'
export const SET_SHOW_MEDIA_COUNT_IN_EXCLUSIVE_CONTENT = 'SET_SHOW_MEDIA_COUNT_IN_EXCLUSIVE_CONTENT'
export const RESET_UNREAD_COUNT = 'RESET_UNREAD_COUNT'
export const LOGOUT_USER_LOADER = 'LOGOUT_USER_LOADER'
export const REMOVE_BLOG_DATA = 'REMOVE_BLOG_DATA'
export const UPDATE_USER_SUBSCRIPTION_PAYMENT_GATEWAY = 'UPDATE_USER_SUBSCRIPTION_PAYMENT_GATEWAY'
export const IS_PROGRESSIVE_WEB_APP_POPUP_VISIBLE = 'IS_PROGRESSIVE_WEB_APP_POPUP_VISIBLE'
export const UPDATE_LINK_PAGE_DATA = 'UPDATE_LINK_PAGE_DATA'
export const PROGRESSIVE_WEB_APP_POPUP_SHOW_AGAIN = 'PROGRESSIVE_WEB_APP_POPUP_SHOW_AGAIN'
export const SET_PUSH_NOTIFICATION_PROMPT = 'SET_PUSH_NOTIFICATION_PROMPT'
export const ONE_SIGNAL_INITIALIZED = 'ONE_SIGNAL_INITIALIZED'
export const SET_CONFIRM_SWEET_ALERT = 'SET_CONFIRM_SWEET_ALERT'
export const REMOVE_CONFIRM_SWEET_ALERT = 'REMOVE_CONFIRM_SWEET_ALERT'
export const SET_LOADER = 'SET_LOADER'
// user influencer help
export const ADD_USER_INFLUENCER_HELP_POPUP = 'ADD_USER_INFLUENCER_HELP_POPUP'
export const USER_INFLUENCER_HELP_POPUP_HIDE = 'USER_INFLUENCER_HELP_POPUP_HIDE'
export const ADD_USER_INFLUENCER_HELP_DETAIL = 'ADD_USER_INFLUENCER_HELP_DETAIL'
export const ADD_USER_INFLUENCER_HELP_LIST = 'ADD_USER_INFLUENCER_HELP_LIST'
export const USER_INFLUENCER_HELP_RESUME_API_CALL = 'USER_INFLUENCER_HELP_RESUME_API_CALL'
export const UPDATE_USER_INFLUENCER_HELP_LIST = 'UPDATE_USER_INFLUENCER_HELP_LIST'
export const ADD_USER_INFLUENCER_HELP_LIST_NOTIFICATION = 'ADD_USER_INFLUENCER_HELP_LIST_NOTIFICATION'
export const UPDATE_USER_INFLUENCER_HELP_LIST_NOTIFICATION = 'UPDATE_USER_INFLUENCER_HELP_LIST_NOTIFICATION'
export const SET_USER_HELP_NOTIFICATION_UNREAD_COUNT = 'SET_USER_HELP_NOTIFICATION_UNREAD_COUNT'
export const RESET_USER_HELP_NOTIFICATION = 'RESET_USER_HELP_NOTIFICATION'
export const SET_USER_NOTIFICATION_FILTER = 'SET_USER_NOTIFICATION_FILTER'
export const SET_USER_HELP_TAG_FILTER = 'SET_USER_HELP_TAG_FILTER'
export const SET_USER_HELP_TAGS = 'SET_USER_HELP_TAGS'
export const UPDATE_UNREAD_COUNT_IN_NAVBAR = 'UPDATE_UNREAD_COUNT_IN_NAVBAR'
// Universal Login
// store merge website list to show in the transaction report
export const UNIVERSAL_LOGIN_MERGE_WEBSITE_LIST = 'UNIVERSAL_LOGIN_MERGE_WEBSITE_LIST'
// store merge website list while user register and login on merge popup
export const UNIVERSAL_LOGIN_WEBSITE_LIST = 'UNIVERSAL_LOGIN_WEBSITE_LIST'
// show universal login merge popup
export const SHOW_UNIVERSAL_LOGIN_ADD_ACCOUNT_POPUP = 'SHOW_UNIVERSAL_LOGIN_ADD_ACCOUNT_POPUP'
// store register while show universal merge popup
export const REGISTER_USER_INFO = 'REGISTER_USER_INFO'
// store login while show universal merge popup
export const LOGIN_USER_INFO = 'LOGIN_USER_INFO'
// manage loading in signin button
export const IS_LOADING_LOGIN_BUTTON = 'IS_LOADING_LOGIN_BUTTON'
// manage loading in signup button
export const IS_LOADING_SIGN_UP_BUTTON = 'IS_LOADING_SIGN_UP_BUTTON'
// show forgot password popup
export const SHOW_FORGOT_PASSWORD_POPUP = 'SHOW_FORGOT_PASSWORD_POPUP'
// set user while register with different password
export const SET_FORGOT_PASSWORD_EMAIL = 'SET_FORGOT_PASSWORD_EMAIL'
// set universal login old user account list
export const UNIVERSAL_LOGIN_OLD_USER_WEBSITE_LIST = 'UNIVERSAL_LOGIN_OLD_USER_WEBSITE_LIST'
// show universal old user merge popup
export const SHOW_OLD_USER_MERGE_ACCOUNT_POPUP = 'SHOW_OLD_USER_MERGE_ACCOUNT_POPUP'
// universal login setting enable
export const IS_ENABLED_UNIVERSAL_LOGIN_SETTING = 'IS_ENABLED_UNIVERSAL_LOGIN_SETTING'
// Payment
export const UPDATE_PAYMENT_PROCESSING_STATUS = 'UPDATE_PAYMENT_PROCESSING_STATUS'
// set different password site
export const UNIVERSAL_LOGIN_DIFFERENT_PASSWORD_SITE_LIST = 'UNIVERSAL_LOGIN_DIFFERENT_PASSWORD_SITE_LIST'
// show universal login different password popup
export const SHOW_DIFFERENT_PASSWORD_SITE_POPUP = 'SHOW_DIFFERENT_PASSWORD_SITE_POPUP'
// set merge old user while show different password popup in register if only found old user with different password
export const SET_MERGE_OLD_USER = 'SET_MERGE_OLD_USER'
export const SUBSCRIPTION_EMAIL_NOTIFICATION_SETTINGS = 'SUBSCRIPTION_EMAIL_NOTIFICATION_SETTINGS'
export const IS_SUBSCRIPTION_EMAIL_NOTIFICATION_SETTINGS_LOADING = 'IS_SUBSCRIPTION_EMAIL_NOTIFICATION_SETTINGS_LOADING'
export const IS_SUBSCRIPTION_EMAIL_NOTIFICATION_BUTTON_LOADING = 'IS_SUBSCRIPTION_EMAIL_NOTIFICATION_BUTTON_LOADING'
// hide email log banner after user verify their email
export const UPDATE_USER_EMAIL_AFTER_VERIFIED = 'UPDATE_USER_EMAIL_AFTER_VERIFIED'
export const SET_RESET_PASSWORD_PAGE_LOADER = 'SET_RESET_PASSWORD_PAGE_LOADER'
export const IS_PAGINATION_ON_EXCLUSIVE_CONTENT_BUTTON_LOADING = 'IS_PAGINATION_ON_EXCLUSIVE_CONTENT_BUTTON_LOADING'
export const IS_PAGINATION_ON_EXCLUSIVE_CONTENT_ENABLED = 'IS_PAGINATION_ON_EXCLUSIVE_CONTENT_ENABLED'
export const SET_IS_PAGINATION_ON_EXCLUSIVE_CONTENT_ENABLED = 'SET_IS_PAGINATION_ON_EXCLUSIVE_CONTENT_ENABLED'
export const IS_PAGINATION_ON_EXCLUSIVE_CONTENT_SETTING_LOADING = 'IS_PAGINATION_ON_EXCLUSIVE_CONTENT_SETTING_LOADING'
export const IS_PAGE_DATA_LOADING = 'IS_PAGE_DATA_LOADING'
export const IS_PAGE_DATA_SAVING = 'IS_PAGE_DATA_SAVING'
export const RESTORE_FIRST_PAGE_BLOGS = 'RESTORE_FIRST_PAGE_BLOGS'
export const SET_UNIVERSAL_LOGIN_DIFFERENT_PASSWORD_POPUP_VISIBILITY = 'SET_UNIVERSAL_LOGIN_DIFFERENT_PASSWORD_POPUP_VISIBILITY'
export const UPDATE_TIPS_PAGE_DATA = 'UPDATE_TIPS_PAGE_DATA'
export const UPDATE_COUPON_LOADING = 'UPDATE_COUPON_LOADING'
export const SET_COUPON_DETAILS = 'SET_COUPON_DETAILS'
export const SET_COUPON_CODE = 'SET_COUPON_CODE'
export const UPDATE_COUPON_STATUS = 'UPDATE_COUPON_STATUS'
export const UPDATE_COUPON_LIST = 'UPDATE_COUPON_LIST'
export const UPDATE_SINGLE_COUPON_IN_LIST = 'UPDATE_SINGLE_COUPON_IN_LIST'
export const UPDATE_COUPON_REPORT_PAGINATION = 'UPDATE_COUPON_REPORT_PAGINATION'
export const UPDATE_APP_SETTING_SINGLE_KEY = 'UPDATE_APP_SETTING_SINGLE_KEY'
export const UPDATE_THEME_2_BANNER_SETTINGS = 'UPDATE_THEME_2_BANNER_SETTINGS'
export const UPDATE_THEME_2_BANNER_SETTINGS_AFTER_FILE_UPLOAD = 'UPDATE_THEME_2_BANNER_SETTINGS_AFTER_FILE_UPLOAD'
// content manager
export const SET_MEDIA = 'SET_MEDIA'
export const ADD_MEDIA = 'ADD_MEDIA'
export const REMOVE_MEDIA = 'REMOVE_MEDIA'
export const SET_PROCESSED_MEDIA = 'SET_PROCESSED_MEDIA'
export const INC_MEDIA_COUNT = 'INC_MEDIA_COUNT'
// content manager category
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const SET_CATEGORY = 'SET_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const ADD_MEDIA_ON_SCROLL = 'ADD_MEDIA_ON_SCROLL'
export const RESET_STATE = 'RESET_STATE'
export const SWITCH_GALLERY = 'SWITCH_GALLERY'
export const SET_ROTATE_CHAT_IMAGE = 'SET_ROTATE_CHAT_IMAGE'
export const UPDATE_SELECTED_CATEGORY = 'UPDATE_SELECTED_CATEGORY'
export const UPDATE_CATEGORY_COUNT = 'UPDATE_CATEGORY_COUNT'
export const UPDATE_MEDIA_COUNT_IN_CATEGORY = 'UPDATE_MEDIA_COUNT_IN_CATEGORY'
export const IS_MESSAGES_LOADING = 'IS_MESSAGES_LOADING'
export const SET_IS_CHAT_SCREEN_OPEN = 'SET_IS_CHAT_SCREEN_OPEN'
export const IS_REDIS_CACHE_SETTING_LOADING = 'IS_REDIS_CACHE_SETTING_LOADING'
export const IS_REDIS_CACHE_ENABLED = 'IS_REDIS_CACHE_ENABLED'
export const IS_REDIS_CACHE_BUTTON_LOADING = 'IS_REDIS_CACHE_BUTTON_LOADING'
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS'
export const IS_READ_MESSAGE_COUNT = 'IS_READ_MESSAGE_COUNT'
export const SHOW_BOOTSTRAP_NAVBAR = 'SHOW_BOOTSTRAP_NAVBAR'
export const UPDATE_PROCESSING_INDEX = 'UPDATE_PROCESSING_INDEX'
export const UPDATE_PROCESSED_INDEX = 'UPDATE_PROCESSED_INDEX'
export const UPDATE_USER_LAST_MESSAGE = 'UPDATE_USER_LAST_MESSAGE'
export const SET_SEARCH_INPUT_VALUE = 'SET_SEARCH_INPUT_VALUE'
export const DELETE_MESSAGE_FROM_ALL = 'DELETE_MESSAGE_FROM_ALL'
export const SET_USER_PROFILE_INFO = 'SET_USER_PROFILE_INFO'
export const SET_LIVE_STREAM_LOADER = 'SET_LIVE_STREAM_LOADER'
export const SET_START_STREAM = 'SET_START_STREAM'
export const SET_IS_LIVE = 'SET_IS_LIVE'
export const SET_STREAM_JOINED = 'SET_STREAM_JOINED'
export const SET_STREAM_TOKEN = 'SET_STREAM_TOKEN'
export const SET_CAN_MODEL_GO_LIVE = 'SET_CAN_MODEL_GO_LIVE'
export const SET_SERVER_TAB_TOKEN = 'SET_SERVER_TAB_TOKEN'
export const SET_ROOM = 'SET_ROOM'
export const SET_PUB_ID = 'SET_PUB_ID'
export const SET_PUB_PVT_ID = 'SET_PUB_PVT_ID'
export const SET_SEND_GO_LIVE_STREAM_MESSAGE = 'SET_SEND_GO_LIVE_STREAM_MESSAGE'
export const SET_LIVE_STREAM_STATUS = 'SET_LIVE_STREAM_STATUS'
export const START_LIVE_STREAM = 'START_LIVE_STREAM'
export const OPEN_COPY_TO_CLIPBOARD_TOAST_WITH_OFFSET = 'OPEN_COPY_TO_CLIPBOARD_TOAST_WITH_OFFSET'
export const SET_ROTATE_CHAT_MASS_MESSAGE = 'SET_ROTATE_CHAT_MASS_MESSAGE'
export const SET_SHOW_ALERT_ON_PAGE_WRAPPER = 'SET_SHOW_ALERT_ON_PAGE_WRAPPER'
export const SET_UNLOCK_CONTENT_DETAIL = 'SET_UNLOCK_CONTENT_DETAIL'
export const SET_SHOW_ADD_FUND_POPUP = 'SET_SHOW_ADD_FUND_POPUP'
export const SET_UNLOCK_DATA = 'SET_UNLOCK_DATA'
export const SET_REMAIN_AMOUNT = 'SET_REMAIN_AMOUNT'
export const SET_UPDATED_BALANCE = 'SET_UPDATED_BALANCE'
export const SET_SHOW_BANNER = 'SET_SHOW_BANNER'
export const RESET_CHAT_DATA = 'RESET_CHAT_DATA'
export const CLEAR_CHAT_TEXT_INPUT = 'CLEAR_CHAT_TEXT_INPUT'
export const IS_CHAT_SCROLL_TO_BOTTOM = 'IS_CHAT_SCROLL_TO_BOTTOM'
export const RESET_LIVE_STREAM_STATE = 'RESET_LIVE_STREAM_STATE'
export const INC_MESSAGE_ID = 'INC_MESSAGE_ID'
export const ADD_USER_IN_ADMIN_CHAT_LIST = 'ADD_USER_IN_ADMIN_CHAT_LIST'
export const UPDATE_USER_UNREAD_COUNT_IN_USER_LIST = 'UPDATE_USER_UNREAD_COUNT_IN_USER_LIST'
export const UPDATE_MODEL_TIPS_UNREAD_COUNT = 'UPDATE_MODEL_TIPS_UNREAD_COUNT'
export const SET_WEBSITE_ID = 'SET_WEBSITE_ID'
export const SET_USER_MERGED_DOMAIN_COUNT = 'SET_USER_MERGED_DOMAIN_COUNT'
