import React, {
    useState,
    useEffect,
    useRef
} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { isIOS } from 'react-device-detect'

const TextArea = styled.textarea`
	min-height: 48px !important;
	max-height: 144px !important;
	height: ${$props => $props.textAreaHeight} !important;
	padding: 11px 16px !important;
	line-height: 24px !important;
	font-size: 16px !important;
	border: 1px solid lightgrey !important;
    position: sticky !important;
`

function AutoExpandingTextArea(props) {
    const textAreaRef = useRef(null)
    const [textAreaHeight, setTextAreaHeight] = useState('auto')
    const [parentHeight, setParentHeight] = useState('auto')

    useEffect(() => {
        setParentHeight(`${textAreaRef.current.scrollHeight}px`)
        setTextAreaHeight(`${textAreaRef.current.scrollHeight + 2}px`)
        if (props.value === '') {
            setParentHeight('auto')
            setTextAreaHeight('auto')
        }
    }, [props.value, props.onClick, props.readOnly, props.required])

    const onChangeHandler = (event) => {
        const textscroll = document.getElementById('textAreaScroll')
        textscroll.scrollIntoView({ behavior: 'smooth', block: 'start' })
        setTextAreaHeight('auto')
        setParentHeight(`${textAreaRef.current.scrollHeight}px`)
        if (window.innerWidth > 991) {
            window.scrollTo(0, document.body.scrollHeight)
        }
        if (props.onChange) {
            props.onChange(event)
        }
    }

    const onfocusHandler = () => {
        const bottomNavBatDivId = document.getElementById('BottomNavBar')
        const removePaddingDivId = document.getElementById('RemovePadding')
        if (bottomNavBatDivId) {
            bottomNavBatDivId.style.bottom = '-90px'
        }
        if (removePaddingDivId) {
            removePaddingDivId.style.paddingBottom = '0px'
        }
    }

    const onBlurHandler = () => {
        const bottomNavBatDivId = document.getElementById('BottomNavBar')
        const removePaddingDivId = document.getElementById('RemovePadding')
        const scrollBottom = document.getElementById('scrollBottom')
        if (removePaddingDivId) {
            removePaddingDivId.style.paddingBottom = `${isIOS && window.matchMedia('(display-mode: standalone)').matches ? '90px' : '60px'}`
            removePaddingDivId.classList.add('p-lg-0')
        }
        if (bottomNavBatDivId) {
            bottomNavBatDivId.style.bottom = '0px'
        }
        if (scrollBottom) {
            scrollBottom.scrollIntoView(false)
        }
    }

    return (
        <div
            style={{
                minHeight: '50px',
                height: parentHeight,
                maxHeight: '144px'
            }}
        >
            <TextArea
                id='textAreaScroll'
                ref={textAreaRef}
                rows={1}
                textAreaHeight={textAreaHeight}
                onChange={onChangeHandler}
                onFocus={onfocusHandler}
                onBlur={onBlurHandler}
                value={props.value}
                placeholder={props.placeholder}
                className={props.className}
                onClick={props.onClick}
                readOnly={props.readOnly}
                required={props.required}
                maxLength={props.maxLength}
                disabled={props.disabled}
            />
        </div>
    )
}

AutoExpandingTextArea.propTypes = {
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    maxLength: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

export default AutoExpandingTextArea
