import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import _ from 'lodash'
import PreviewContent from '../content/PreviewContent'
import {
    setSweetAlert,
    setShowAlertOnPageWrapper,
    setConfirmSweetAlert
} from '../../actions/sweetAlertActions'
import {
    sendMessage,
    addNewMessageInEnd,
    updateUserList,
    updateUsersLastMessage,
    clearChatTextInput,
    unlockContentDetail
} from '../../actions/chatActions'
import { FILE_SIZE_LIMIT_IN_BYTE } from '../../utils/constant'
import classNames from 'classnames'
import { getFileExtension, sortArrayById } from '../../utils/common'
import { getPresignedUrl } from '../../utils/getPresignedUrl'
import axios from 'axios'
import AutoExpandingTextArea from '../Layouts/AutoExpandingTextArea'
import FullScreenModelPopUpDialog from '../Layouts/FullScreenModelPopUpDialog'
import { toggleChatTipPopup } from '../../actions/chatActions'
import { updateWalletAmount } from '../../actions/cryptoPaymentActions'
import MediaSelectionPopup from '../media/MediaSelectionPopup'
import { updateMediaCountInCategory } from '../../actions/mediaAction'
import { MP4, MOV, M4V } from '../../utils/constant'
import ChatInput from './ChatInput'
import socket from '../../utils/socket'
import { googleAnalyticsTrackEvent } from '../../utils/GoogleAnalyticsEvent'
import { TipPayment } from '../../actions/hybridPaymentAction'

const ButtonGroup = styled.div`
    width:100%;

    button.btn.mb-2.p-2.shadow-none.m-0:hover {
        background-color: ${props => props.content_color};
        color: ${props => props.content_font_color} !important;
        outline: 1px solid ${props => props.content_color} !important;
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
    }

    button.btn.mb-2.p-2.m-0.border-2:focus {
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
    }
    button.btn.selectedOption {
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 5px 15px 0 ${props => props.button_shadow_color}44 !important;
    }

    button.btn.mb-2.p-2.shadow-none.m-0:focus-visible, button.btn.mb-2.p-2.shadow-none.m-0:focus {
        outline: transparent !important;
    }

    button.rounded-start {
        border-bottom-left-radius: .25rem !important;
        border-top-left-radius: .25rem !important;
    }

    button.rounded-end {
        border-bottom-right-radius: .25rem !important;
        border-top-right-radius: .25rem !important;
    }
        

    @media (max-width: 1279px) and (min-width: 991px) {
        button.btn.mb-2.p-2.shadow-none.m-0 {
            font-size: 0.7125rem;
        }
    }
`

const StyledButton = styled.button`
    border-radius: 0% 0% 0% 0% !important;
    background-color: ${props => props.backgroundColor} !important;
    color: ${props => props.color} !important;
    border-color: ${props => props.borderColor} !important;
`

function ChatInputNewUI(props) {

    const {
        content_color,
        content_font_color,
        card_background_color,
        site_font_color,
        tips_minimum_amount,
        tips_maximum_amount,
        button_shadow_color,
        is_pay_per_message_enabled,
        pay_per_message_amount,
        content_unlock_minimum_amount,
        content_unlock_maximum_amount,
        model_id
    } = props.auth.appSettings

    const { allMedia, category } = props.media
    const { isAdmin, payPerMessageCredit, default_payment_method } = props.auth.user
    const { isLoading } = props.ccbill
    const { showChatTipPopup, unlockPaymentData } = props.chat

    const [openDialog, setOpenDialog] = useState(false)
    const [messageType, setMessageType] = useState('text')
    const [media, setMedia] = useState([])
    const [mediaPreview, setMediaPreview] = useState([])
    const [locked, setLocked] = useState(isAdmin ? 'locked' : 'free')
    const [amount, setAmount] = useState(20)
    const [chatInput, setChatInput] = useState('')
    const [tipMessage, setTipMessage] = useState('')
    const [isRearrangeModeEnable, setIsRearrangeModeEnable] = useState(false)
    const [isRearrangeModeEnableForMedia, setIsRearrangeModeEnableForMedia] = useState(false)
    const [dragId, setDragId] = useState(false)
    const [uploadProgress, setUploadProgress] = useState({})
    const [isPreviewModeEnable, setIsPreviewModeEnable] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [url, setUrl] = useState('')
    const [type, setType] = useState('')
    const [selectFromContentManager, setSelectFromContentManager] = useState(isAdmin ? true : false)
    const [showMediaSelectionPopup, setShowMediaSelectionPopup] = useState(false)
    const [showMediaPreviewSelectionPopup, setShowMediaPreviewSelectionPopup] = useState(false)
    const [contentManagerMedia, setContentManagerMedia] = useState([])
    const [contentManagerMediaPreview, setContentManagerMediaPreview] = useState([])
    const [contentManagerIsPreviewModeEnable, setContentManagerIsPreviewModeEnable] = useState(false)
    const [mediaPreviewId, setMediaPreviewId] = useState([])
    const [mediaIds, setMediaIds] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [payPerMessageAmount, setPayPerMessageAmount] = useState(pay_per_message_amount)

    const scrollToBottom = () => {
        setTimeout(() => {
            const objDiv = document.getElementById('message-list')
            if (objDiv) {
                objDiv.scrollTop = objDiv.scrollHeight
            }
        }, 0)
    }

    useEffect(() => {
        if (showChatTipPopup === false && messageType === 'tip') {
            resetForm()
        }
    }, [showChatTipPopup, type])

    const openInput = (type) => {
        setMessageType(type)
        if (openDialog === true) {
            setMessageType('text')
            setMedia([])
            setMediaPreview([])
            setContentManagerMedia([])
            setContentManagerMediaPreview([])
            setLocked(isAdmin ? 'locked' : 'free')
            setAmount(20)
            setIsRearrangeModeEnable(false)
            setIsPreviewModeEnable(false)
            setContentManagerIsPreviewModeEnable(false)
            setDragId(false)
            setUploadProgress({})
            props.toggleChatTipPopup(false)
            setMediaPreviewId([])
            setMediaIds([])
            setSelectedCategory(null)
        }

        if (showChatTipPopup === false && type === 'tip') {
            props.toggleChatTipPopup(true)
        }
        setOpenDialog(!openDialog)
    }

    useEffect(() => {
        const inputType = props.inputType
        if (props.inputType) {
            openInput(inputType)
        }
    }, [props.inputType])

    useEffect(() => {
        if (unlockPaymentData.isTipFromChat) {
            sendTip()
        }
    }, [unlockPaymentData.isTipFromChat])

    useEffect(() => {
        if (unlockPaymentData.isPayPerMessageMedia) {
            handleSendMessage(messageType, true)
        }
    }, [unlockPaymentData.isPayPerMessageMedia])

    useEffect(() => {
        if (unlockPaymentData.addFundsForPayPerMessageMedia === true) {
            const wallet_amount = props.auth.user.wallet_amount
            const remainAmount = wallet_amount === 0.00 ? 0 : Math.ceil(payPerMessageAmount - wallet_amount)
            props.setShowAlertOnPageWrapper(false)
            props.unlockContentDetail({})
            props.payPerMessageCryptoPayment({ showAddFundPopup: true, remainAmount: remainAmount, tipAmount: pay_per_message_amount })
        }
    }, [unlockPaymentData.addFundsForPayPerMessageMedia])

    const resetForm = () => {
        props.setInputType('')
        props.setMessage('')
        setMessageType('text')
        setMedia([])
        setMediaPreview([])
        setContentManagerMedia([])
        setContentManagerMediaPreview([])
        setLocked(isAdmin ? 'locked' : 'free')
        setAmount(20)
        setOpenDialog(false)
        setChatInput('')
        setTipMessage('')
        setIsRearrangeModeEnable(false)
        setDragId(false)
        setIsPreviewModeEnable(false)
        setContentManagerIsPreviewModeEnable(false)
        setUploadProgress({})
        setUploading(false)
        props.toggleChatTipPopup(false)
        setMediaPreviewId([])
        setMediaIds([])
        setSelectedCategory(null)
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        if (chatInput === '' && messageType === 'text') {
            return props.setSweetAlert({ description: 'Type a message' })
        }
        if (['photo', 'video', 'gallery'].includes(messageType) && media.length === 0 && contentManagerMedia.length === 0) {
            return props.setSweetAlert({ description: 'Please add media' })
        }
        let messageObject = {}
        if (isAdmin) {
            if (_.isEmpty(selectedCategory) === false) {
                const slug = selectedCategory.value.trim().toLowerCase()
                const isNew = _.get(selectedCategory, '__isNew__', false)
                let errorMessage = ''
                if (slug === '') {
                    errorMessage = 'Category not allow empty'
                } else if (isNew) {
                    if (slug.length > 30) {
                        errorMessage = 'Category name cannot be more than 30 characters.'
                    }
                    const isCategoryExist = category.find(item => item.slug === slug)
                    if (isCategoryExist !== undefined) {
                        errorMessage = 'Category already exist.'
                    }
                }
                if (!_.isEmpty(errorMessage)) {
                    return props.setSweetAlert({ description: errorMessage })
                }
            }
            if (messageType === 'text') {
                messageObject = {
                    sender: model_id,
                    receiver: props.chat.selectedUserId,
                    type: messageType,
                    fromAdmin: true,
                    message: chatInput,
                    userId: props.chat.selectedUserId
                }
                setUploading(true)
                props.setInputType('')
                props.onSend(messageObject, () => {
                    resetForm()
                })
                return
            } else {
                if (locked === 'locked') {
                    if ((amount < content_unlock_minimum_amount) || (amount > content_unlock_maximum_amount)) {
                        const description = `Choose an amount between ${content_unlock_minimum_amount} and ${content_unlock_maximum_amount}`
                        return props.setSweetAlert({ description: description })
                    }
                }
                messageObject = {
                    sender: model_id,
                    receiver: props.chat.selectedUserId,
                    type: messageType,
                    fromAdmin: true,
                    message: props.message,
                    isRead: 0,
                    isLocked: locked,
                    media: [],
                    media_preview: [],
                    processing: true,
                    amount,
                    isSelectedContentManager: selectFromContentManager,
                    category: selectedCategory
                }
                setUploading(true)
                let contentLeftForProcessing = 0
                if (selectFromContentManager) {
                    messageObject.media_item = mediaIds
                } else {
                    for (let index = 0; index < media.length; index++) {

                        const element = media[index]

                        let fileExtension = getFileExtension(element.selectedFile.name)
                        if ([M4V].includes(fileExtension.toLocaleLowerCase())) {
                            setUploading(false)
                            return props.setSweetAlert({ description: 'Media format is not supported.' })
                        }

                        let fileExtensionForPresignedUrl = 'photo'
                        if ([MP4, MOV].includes(fileExtension.toLocaleLowerCase())) {
                            fileExtensionForPresignedUrl = 'video'
                        }

                        props.updateMediaCountInCategory({ slug: 'all media', mediaType: fileExtensionForPresignedUrl })
                        let domain = ''
                        const userList = props.chat.userList
                        if (userList?.length > 1 && !props.auth.isAdmin) {
                            const selectedUser = userList.find(user => user._id === props.chat.selectedUserId)
                            domain = selectedUser.domain
                        }
                        let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'message', fileExtensionForPresignedUrl, props.auth.user.email, domain)
                        if (presignedUrlData.presigned_url !== '') {
                            let uploadFile = await uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index)
                            if (uploadFile === true) {
                                messageObject.media.push({
                                    url: presignedUrlData.file_name,
                                    thumbnail_url: '',
                                    blur_url: '',
                                    is_process: true,
                                    content_type: fileExtensionForPresignedUrl,
                                    is_error: false
                                })
                                contentLeftForProcessing++
                            } else {
                                setUploading(false)
                                return props.setSweetAlert({ description: presignedUrlData.message })
                            }
                        } else {
                            setUploading(false)
                            return props.setSweetAlert({ description: presignedUrlData.message })
                        }
                    }
                }

                // preview image upload
                if (selectFromContentManager) {
                    messageObject.media_preview_item = mediaPreviewId.length > 0 ? mediaPreviewId[0] : null
                } else {
                    for (let index = 0; index < mediaPreview.length; index++) {
                        const element = mediaPreview[index]

                        let fileExtension = getFileExtension(element.selectedFile.name)
                        if ([M4V].includes(fileExtension.toLocaleLowerCase())) {
                            setUploading(false)
                            return props.setSweetAlert({ description: 'Media format is not supported.' })
                        }

                        let fileExtensionForPresignedUrl = 'photo'
                        if ([MP4, MOV].includes(fileExtension.toLocaleLowerCase())) {
                            fileExtensionForPresignedUrl = 'video'
                        }

                        props.updateMediaCountInCategory({ slug: 'all media', mediaType: fileExtensionForPresignedUrl })
                        props.updateMediaCountInCategory({ slug: 'preview', mediaType: fileExtensionForPresignedUrl })
                        let domain = ''
                        const userList = props.chat.userList
                        if (userList?.length > 1 && !props.auth.isAdmin) {
                            const selectedUser = userList.find(user => user._id === props.chat.selectedUserId)
                            domain = selectedUser.domain
                        }
                        let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'message', fileExtensionForPresignedUrl, props.auth.user.email, domain)
                        if (presignedUrlData.presigned_url !== '') {
                            let uploadFile = await uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index, 'preview')
                            if (uploadFile === true) {
                                messageObject.media_preview.push({
                                    url: presignedUrlData.file_name,
                                    thumbnail_url: '',
                                    is_process: true,
                                    content_type: fileExtensionForPresignedUrl
                                })
                                contentLeftForProcessing++
                            } else {
                                setUploading(false)
                                return props.setSweetAlert({ description: presignedUrlData.message })
                            }
                        } else {
                            setUploading(false)
                            return props.setSweetAlert({ description: presignedUrlData.message })
                        }
                    }
                }
                messageObject.contentLeftForProcessing = contentLeftForProcessing
                const res = await props.sendMessage(messageObject)
                const resMessage = res.message
                if (!_.isEmpty(resMessage)) {
                    const updatedUserList = props.chat.userList.map(user => {
                        if (user._id === resMessage.receiverId) {
                            user.last_message = resMessage.message ? resMessage.message : `You sent a ${resMessage.type}`
                            user.last_message_time = resMessage.created
                        }
                        return user
                    })
                    props.updateUserList(updatedUserList, window.location.hostname)
                }
                setUploading(false)
                resetForm()
                props.addNewMessageInEnd(res.message, props.chat.selectedUserId)
                scrollToBottom()
            }
        } else {
            if (chatInput.length > 20000) {
                return props.setSweetAlert({ description: 'Message is too long' })
            }
            const currentURL = window.location.hostname
            const userId = props.chat.selectedUserId
            const userDomain = props.chat.userList.filter(obj => obj._id === userId)
            const domain = _.get(userDomain, '[0].domain', '')

            if (props.auth.user.universal_login === true && domain !== currentURL) {
                const data = {
                    email: props.auth.user.email,
                    isUniversal: true,
                    modelDomain: domain
                }

                socket.emit('IS_ENABLE_PAY_PER_MESSAGE', data)

                const handlePayPerMessageStatus = (data) => {
                    const { isPayPerMessageEnabled, payPerMessageAmount, payPerMessageCredit } = data
                    if (isPayPerMessageEnabled === true) {
                        setPayPerMessageAmount(payPerMessageAmount)
                        checkPayPerMessageEnable(isPayPerMessageEnabled, payPerMessageAmount, payPerMessageCredit)
                    } else {
                        handleSendMessage(messageType, isPayPerMessageEnabled)
                    }
                    socket.off('PAY_PER_MESSAGE_STATUS', handlePayPerMessageStatus)
                }

                socket.on('PAY_PER_MESSAGE_STATUS', handlePayPerMessageStatus)
            } else {
                if (is_pay_per_message_enabled) {
                    checkPayPerMessageEnable(is_pay_per_message_enabled)
                } else {
                    handleSendMessage(messageType, is_pay_per_message_enabled)
                }
            }
        }
    }

    const handleSendMessage = async (messageType, isPayPerMessageEnabled) => {
        let messageObject = {}
        props.setShowAlertOnPageWrapper(false)
        props.unlockContentDetail({})
        if (messageType === 'text') {
            messageObject = {
                type: messageType,
                fromAdmin: false,
                message: chatInput || props.message,
                receiver: props.chat.selectedUserId,
                messageFrom: 'chat',
                userId: props.auth.user._id
            }
            setUploading(true)
            props.onSend(messageObject, () => {
                resetForm()
                props.payPerMessageCreditRemove()
            })
        } else {
            messageObject = {
                type: messageType,
                fromAdmin: false,
                message: chatInput || props.message,
                isRead: 0,
                isLocked: locked,
                amount: amount,
                receiver: props.chat.selectedUserId,
                messageFrom: 'chat',
                media: [],
                media_preview: [],
                processing: true
            }

            setUploading(true)
            let contentLeftForProcessing = 0
            for (let index = 0; index < media.length; index++) {

                const element = media[index]

                let fileExtension = getFileExtension(element.selectedFile.name)
                if ([M4V].includes(fileExtension.toLocaleLowerCase())) {
                    setUploading(false)
                    return props.setSweetAlert({ description: 'Media format is not supported.' })
                }

                let fileExtensionForPresignedUrl = 'photo'
                if ([MP4, MOV].includes(fileExtension.toLocaleLowerCase())) {
                    fileExtensionForPresignedUrl = 'video'
                }

                let domain = ''
                const userList = props.chat.userList
                if (userList?.length > 1 && !props.auth.isAdmin) {
                    const selectedUser = userList.find(user => user._id === props.chat.selectedUserId)
                    domain = selectedUser.domain
                }
                let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'message', fileExtensionForPresignedUrl, props.auth.user.email, domain)
                if (presignedUrlData.presigned_url !== '') {
                    let uploadFile = await uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index)
                    if (uploadFile === true) {
                        messageObject.media.push({
                            url: presignedUrlData.file_name,
                            thumbnail_url: '',
                            blur_url: '',
                            is_process: true,
                            content_type: fileExtensionForPresignedUrl,
                            is_error: false
                        })
                        contentLeftForProcessing++
                    } else {
                        setUploading(false)
                        return props.setSweetAlert({ description: presignedUrlData.message })
                    }
                } else {
                    setOpenDialog(false)
                    resetForm()
                    setUploading(false)
                    return props.setSweetAlert({ description: presignedUrlData.message })
                }

                messageObject.contentLeftForProcessing = contentLeftForProcessing
                const res = await props.sendMessage(messageObject)
                setUploading(false)

                if (res.isError === true) {
                    setUploadProgress({})
                    const allow_cascade = _.get(res.error.response.data.errors, 'cascade.allow_cascade', false)
                    // if image upload is failed then update local message credit
                    if (isPayPerMessageEnabled === true) {
                        const paymentStatus = _.get(res.error.response, 'data.errors.isPaymentDoneForPayPerMessage', false)
                        if (paymentStatus === true) {
                            props.payPerMessageCreditAdd()
                        }
                    }
                    let errorMessage = _.get(res.error.response, 'data.message', 'There was an error while uploading the image.')
                    if (allow_cascade === true) {
                        errorMessage = 'Could not send Message: Problem in card authorization please re-enter your card details or add new card.'
                    }
                    props.setSweetAlert({ description: errorMessage })
                    if (allow_cascade === true) {
                        props.history.push('profile/add-new-payment-method')
                    }
                } else {
                    const updatedWalletBalance = _.get(res, 'message.wallet_balance', false)
                    if (updatedWalletBalance !== false) {
                        props.updateWalletAmount(updatedWalletBalance)
                    }
                    props.addNewMessageInEnd(res.message, props.chat.selectedUserId)
                    scrollToBottom()
                    props.payPerMessageCreditRemove()
                    resetForm()
                }
            }
        }
    }

    const checkPayPerMessageEnable = (isEnablePayPerMessage, messageAmount = '', payPerMessageCredit = 0) => {
        if (isEnablePayPerMessage === true && isAdmin === false) {
            if (props.auth.user.default_payment_method === 'crypto_currency') {
                const wallet_amount = props.auth.user.wallet_amount
                const pay_per_message_amount = messageAmount === '' ? props.auth.appSettings.pay_per_message_amount : messageAmount
                if (wallet_amount < pay_per_message_amount) {
                    props.setShowAlertOnPageWrapper(true)
                    props.setConfirmSweetAlert({ description: 'Your wallet balance is low. Please add funds to send message.' })
                    props.unlockContentDetail({ type: 'add_funds_for_pay_per_message_media' })
                    return
                }
            }
            payPerMessageConfirmationAndAlert(messageAmount, payPerMessageCredit)
            return
        }
    }

    const payPerMessageConfirmationAndAlert = (messageAmount, messageCredit) => {
        let confirmationMessage
        const payPerMediaMessageCredit = messageCredit !== 0 ? messageCredit : payPerMessageCredit
        if (payPerMediaMessageCredit === 0) {
            const payPerMessageAmount = messageAmount === '' ? pay_per_message_amount : messageAmount
            if (props.auth.user.default_payment_method === 'crypto_currency') {
                confirmationMessage = `You currently have $${props.auth.user.wallet_amount} in your wallet. Sending this message will charge $${payPerMessageAmount}. It will be debited from your wallet balance.`
            } else {
                confirmationMessage = `Sending this message will charge $${payPerMessageAmount} to the payment method on file for your account.`
            }
        } else {
            confirmationMessage = `You have ${payPerMediaMessageCredit} Message credit. This message is sent free.`
        }

        props.setShowAlertOnPageWrapper(true)
        props.setConfirmSweetAlert({ description: confirmationMessage })
        props.unlockContentDetail({ type: 'pay_per_message_media' })
    }

    const onSendTip = async () => {
        let tipAmount = parseFloat(amount).toFixed(2)
        if (tipMessage.length > 20000) {
            return props.setSweetAlert({ description: 'Message is too long.' })
        }
        props.setInputType('')

        let amountInt = parseInt(amount, 10)
        let tipsMinimumAmount = parseInt(props.auth.appSettings.tips_minimum_amount, 10)
        let tipsMaximumAmount = parseInt(props.auth.appSettings.tips_maximum_amount, 10)

        if (!((tipsMinimumAmount <= amountInt) && (amountInt <= tipsMaximumAmount))) {
            props.setSweetAlert({ description: `Choose a tip between ${tipsMinimumAmount} and ${tipsMaximumAmount}` })
        } else {
            if (!/^[0-9]+(\.[0-9]{1,2})$/.test(tipAmount)) {
                tipAmount = amount + '.00'
            }
            setAmount(tipAmount)
            // Set google analytics add_to_cart event for tip
            googleAnalyticsTrackEvent('add_to_cart', '', amount, 'chat', 'tip', '')
            let confirmMessage = `Please Confirm Tip of $${tipAmount}.`
            if (props.auth.user.default_payment_method === 'crypto_currency') {
                confirmMessage += ` You currently have $${props.auth.user.wallet_amount} in your wallet. Amount will be debited from your wallet balance.`
            }

            if (props.auth.user.default_payment_method !== 'crypto_currency') {
                props.setShowAlertOnPageWrapper(true)
                props.setConfirmSweetAlert({ description: confirmMessage })
                props.unlockContentDetail({ type: 'tips_from_chat' })
            } else if (props.auth.user.default_payment_method === 'crypto_currency' && props.auth.user.wallet_amount >= tipAmount) {
                props.setShowAlertOnPageWrapper(true)
                props.setConfirmSweetAlert({ description: confirmMessage })
                props.unlockContentDetail({ type: 'tips_from_chat' })
            } else {
                sendTip()
            }
        }
    }

    const sendTip = async () => {
        let data = {
            amount: amount,
            recurring: false,
            email: props.auth.user.email,
            action: 'tips',
            tipFrom: 'chat',
            tipMessage: tipMessage.trim()
        }
        if (props.auth.user.universal_login === true) {
            const userId = props.chat.selectedUserId
            const userDomain = props.chat.userList.filter(obj => obj._id === userId)
            data.domain = userDomain[0].domain
        }
        if (props.auth.user.default_payment_method === 'crypto_currency') {
            props.tipUsingCrypto(amount, props.auth.user.wallet_amount, tipMessage, (res) => {
                tipResponseHandler(res)
                props.setShowAlertOnPageWrapper(false)
                props.unlockContentDetail({})
            })
        } else {
            const payment = await props.TipPayment(data, props.auth.user._id)
            payment.show_alert = false
            tipResponseHandler(payment)
            props.setShowAlertOnPageWrapper(false)
            props.unlockContentDetail({})
        }
    }

    const tipResponseHandler = (res) => {
        if (res.success === 1) {
            props.setSweetAlert({ description: res.data.message })
            props.addNewMessageInEnd(res.data.messageData, props.chat.selectedUserId)
            const lastMessage = `You just tipped <model_name> ${res.data.messageData.message}`
            props.updateUsersLastMessage({ user_id: props.chat.selectedUserId, message: lastMessage, type: res.data.messageData.type, isAdmin: false })
            scrollToBottom()
        }
        resetForm()
    }

    const reArrangeImage = (dragIndex, dropIndex) => {
        let copyNewFiles = selectFromContentManager ? [...contentManagerMedia] : [...media]
        const draggedImage = copyNewFiles[dragIndex]
        copyNewFiles[dragIndex] = copyNewFiles[dropIndex]
        copyNewFiles[dropIndex] = draggedImage
        selectFromContentManager ? setContentManagerMedia(copyNewFiles) : setMedia(copyNewFiles)
    }

    const handleDrag = (index) => {
        if (isRearrangeModeEnable === true || isRearrangeModeEnableForMedia) {
            setDragId(index)
        }
    }

    const handleDrop = (index) => {
        if ((isRearrangeModeEnable === true || isRearrangeModeEnableForMedia) && dragId !== '') {
            const copyNewFiles = selectFromContentManager ? [...contentManagerMedia] : [...media]
            const dragFile = copyNewFiles[dragId]
            copyNewFiles.splice(dragId, 1)
            copyNewFiles.splice(index, 0, dragFile)
            selectFromContentManager ? setContentManagerMedia(copyNewFiles) : setMedia(copyNewFiles)
            selectFromContentManager && setMediaIds(copyNewFiles.filter(file => file.selectedFile).map(file => file.selectedFile._id))
            setDragId('')
        }
        setDragId('')
    }

    const handleDeletePhoto = (index, name) => {
        if (name === 'preview') {
            selectFromContentManager ? setContentManagerMediaPreview([]) : setMediaPreview([])
            setMediaPreviewId([])
        } else {
            if (media.length === 2) {
                setIsRearrangeModeEnable(false)
                setIsRearrangeModeEnableForMedia(false)
            }
            const copyMedia = selectFromContentManager ? [...contentManagerMedia] : [...media]
            const updatedFilesArray = [
                ...copyMedia.slice(0, index),
                ...copyMedia.slice(index + 1)
            ]
            const updatedMediaIdsArray = [
                ...mediaIds.slice(0, index),
                ...mediaIds.slice(index + 1)
            ]
            let type = 'gallery'
            if (updatedFilesArray.length === 0 && mediaPreview.length === 0) {
                type = 'text'
            } else if (updatedFilesArray.length === 1) {
                if (['video/mp4', 'video/quicktime'].includes(updatedFilesArray[0].selectedFile.type)) {
                    type = 'video'
                } else if (['image/jpeg', 'image/jpeg', 'image/png'].includes(updatedFilesArray[0].selectedFile.type)) {
                    type = 'photo'
                }
            }
            setMessageType(type)
            selectFromContentManager ? setContentManagerMedia(updatedFilesArray) : setMedia(updatedFilesArray)
            setMediaIds(updatedMediaIdsArray)
        }
    }

    const uploadProgressInMb = (galleryIndex, progressNumber) => {
        let actualFileSize = 0
        let actualFileUploaded = '0'
        const type = _.get(uploadProgress, 'type', '')
        const selectedFile = type === 'original' ? media[galleryIndex].selectedFile : mediaPreview[galleryIndex].selectedFile
        actualFileSize = (_.get(selectedFile, 'size', 0) / 1024) / 1024
        actualFileUploaded = ((actualFileSize * progressNumber) / 100).toFixed(2)

        return <div className='progress-text' style={{ color: site_font_color }}>Uploading {actualFileUploaded} MB Of {actualFileSize.toFixed(2)} MB</div>
    }

    const handleGalleryChange = (e, name) => {
        const files = e.target.files
        if (name === 'preview') {
            if (files.length > 1) {
                return props.setSweetAlert({ description: 'You can not add more than one preview.' })
            } else {
                handleAddFile(files[0], name)
            }
        } else {
            galleryFiles(files, name)
        }
    }

    const galleryFilesFromPreview = (files) => {
        if (mediaPreview.length === 0) {
            galleryFiles([files[0]], 'preview')
        } else {
            return props.setSweetAlert({ description: 'You can not add more than one preview.' })
        }
    }

    const galleryFilesFromOriginal = (files) => {
        if (isAdmin === true) {
            galleryFiles(files, 'original')
        } else {
            if (media.length === 0) {
                galleryFiles([files[0]], 'original')
            } else {
                return props.setSweetAlert({ description: 'You can not add more than one media.' })
            }
        }
    }

    const galleryFiles = (files, name) => {
        if (isRearrangeModeEnable !== true || isRearrangeModeEnableForMedia !== true) {
            const existFilesCount = media.length
            const uploadFileCount = 45 - existFilesCount < files.length ? 45 - existFilesCount : files.length
            const totalFilesCount = files.length + existFilesCount
            let type = 'gallery'
            for (let index = 0; index < uploadFileCount; index++) {
                const acceptExtension = ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/jpeg', 'image/png']
                if (!acceptExtension.includes(files[index].type)) {
                    return props.setSweetAlert({ description: 'Media format is not supported.' })
                }
                if (totalFilesCount === 1) {
                    if (['video/mp4', 'video/quicktime'].includes(files[index].type)) {
                        type = 'video'
                    } else if (['image/jpeg', 'image/jpeg', 'image/png'].includes(files[index].type)) {
                        type = 'photo'
                    }
                }
            }
            const newUploadedFiles = []
            const mediaNewUploadedFiles = []
            for (let index = 0; index < uploadFileCount; index++) {
                const fileSize = _.get(files[index], 'size', -1)
                if (fileSize !== -1 && fileSize > FILE_SIZE_LIMIT_IN_BYTE) {
                    props.setSweetAlert({ description: `File ${files[index].name} size is too large.` })
                } else {
                    const file = files[index]
                    if (file) {
                        const newFileName = file.name
                        const newFileSize = file.size
                        const galleries = (name === 'original') ? media : mediaPreview
                        let findRecord = -1
                        if (name === 'original') {
                            findRecord = _.findIndex(galleries, function (n) {
                                return (n.selectedFile.name === newFileName && n.selectedFile.size === newFileSize) ? n : false
                            })
                        }
                        if (findRecord === -1) {
                            let newFile = {
                                selectedFile: file,
                                renderFile: file.format === 'modern' ? file.path : URL.createObjectURL(file)
                            }
                            if (name === 'original') {
                                selectFromContentManager ? mediaNewUploadedFiles.push(newFile) : newUploadedFiles.push(newFile)
                            } else {
                                selectFromContentManager ? setContentManagerMediaPreview([newFile]) : setMediaPreview([newFile])
                            }
                        }
                    }
                }
            }
            if (totalFilesCount > 45) {
                props.setSweetAlert({ description: `You have selected ${totalFilesCount} images, maximum 45 images allowed.` })
            } else {
                let copyNewFiles = [...media]
                copyNewFiles = copyNewFiles.concat(newUploadedFiles)
                if (name === 'original') {
                    setMessageType(type)
                    selectFromContentManager ? setContentManagerMedia(mediaNewUploadedFiles) : setMedia(copyNewFiles)
                }
            }
        }
    }

    const handleAddFile = (file, name) => {
        if (file) {
            const newFileName = file.name
            const newFileSize = file.size
            const galleries = (name === 'original') ? media : mediaPreview
            let findRecord = -1
            if (name === 'original') {
                findRecord = _.findIndex(galleries, function (n) {
                    return (n.selectedFile.name === newFileName && n.selectedFile.size === newFileSize) ? n : false
                })
            }
            if (findRecord === -1) {
                let newFile = {
                    selectedFile: file,
                    renderFile: file.format === 'modern' ? file.path : URL.createObjectURL(file)
                }
                if (name === 'original') {
                    const copy = JSON.parse(JSON.stringify(media))
                    copy.push(newFile)
                    selectFromContentManager ? setContentManagerMedia(copy) : setMedia(copy)
                } else {
                    selectFromContentManager ? setContentManagerMediaPreview([newFile]) : setMediaPreview([newFile])
                }
            }
        }
    }

    const uploadFileUsingPresignedUrl = async (contentType, url, body, galleryIndex = 0, type = 'original') => {
        const cancelTokenSource = axios.CancelToken.source()
        const config = {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                window.addEventListener('offline', function () {
                    cancelTokenSource.cancel('Network error')
                    setUploading(false)
                    return props.setSweetAlert({ description: 'Seems you are offline. Please check your internet connection and post again.' })
                })

                const object = {
                    progress: percentCompleted + '%',
                    index: galleryIndex,
                    progressNumber: percentCompleted,
                    type: type
                }
                setUploadProgress(object)
            },
            cancelToken: cancelTokenSource.token
        }
        const axiosInstance = axios.create()
        delete axiosInstance.defaults.headers.common['Authorization']
        axiosInstance.defaults.headers['Content-Type'] = contentType === 'video' ? 'video/mp4' : 'image/jpg'
        let apiResponse = await axiosInstance.put(url, body, config)
        if (apiResponse.status === 200) {
            return true
        }
        return false
    }

    const onChangePreviewMode = () => {
        if (isPreviewModeEnable === true || contentManagerIsPreviewModeEnable === true) {
            selectFromContentManager ? setContentManagerMediaPreview([]) : setMediaPreview([])
            setMediaPreviewId([])
        }
        selectFromContentManager ? setContentManagerIsPreviewModeEnable(!contentManagerIsPreviewModeEnable) : setIsPreviewModeEnable(!isPreviewModeEnable)

    }

    const onOpenDialog = (url, type) => {
        if (uploading === true) {
            return
        }
        if (!isDialogOpen === true) {
            document.querySelector('body').style.overflow = 'hidden'
        } else {
            document.querySelector('body').style.overflow = 'visible'
        }
        setUrl(url)
        setType(type)
        setIsDialogOpen(!isDialogOpen)
    }

    const handleMedia = (selectedMediaIds, name) => {
        if (name == 'preview') {
            setShowMediaPreviewSelectionPopup(false)
            if (selectedMediaIds.length > 1) {
                props.setSweetAlert({ description: 'You can not add more than one preview.' })
                return
            }
        } else {
            setShowMediaSelectionPopup(false)
        }
        if (name === 'preview') {
            setMediaPreviewId(selectedMediaIds)
        } else {
            setMediaIds(selectedMediaIds)
        }
        const selectedMediaObjects = allMedia.filter(media => selectedMediaIds.includes(media._id))
        const sortedMedia = sortArrayById(selectedMediaObjects, selectedMediaIds)
        if (sortedMedia.length > 0) {
            const selectedFiles = sortedMedia.map(item => {
                return {
                    _id: item._id,
                    path: item.path,
                    small_thumb: item.small_thumb || item.path,
                    format: 'modern',
                    type: item.type === 'photo' ? 'image/jpeg' : 'video/mp4',
                    size: parseInt(item.size),
                    name: item._id + (item.type === 'photo' ? '.jpeg' : '.mp4')
                }
            })
            galleryFiles(selectedFiles, name)
        }
    }

    const handleClose = () => {
        openInput()
        props.setInputType('')
    }

    return (
        <>
            {showChatTipPopup && messageType === 'tip' &&
                <WebsiteFontColor>
                    <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                        <div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
                            <div className='modal-content'>
                                <div className='modal-header' style={{ backgroundColor: card_background_color }}>
                                    <h5 className='modal-title'>Send Tip</h5>
                                    <button className='close' onClick={() => {
                                        props.setInputType('')
                                        resetForm()
                                    }} disabled={isLoading} style={{ color: site_font_color, opacity: 1 }} />
                                </div>
                                <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                    <div className='container p-0'>
                                        <form autoComplete='off'>
                                            <div className='form-row'>
                                                <div className='form-group col'>
                                                    <div className='input-group'>
                                                        <div className='input-group-prepend'>
                                                            <span className='input-group-text form-control'>$</span>
                                                        </div>
                                                        <input
                                                            type='text'
                                                            id='amount'
                                                            name='amount'
                                                            className='form-control'
                                                            value={amount}
                                                            onChange={(e) => setAmount(e.target.value)}
                                                            min={tips_minimum_amount}
                                                            max={tips_maximum_amount}
                                                            disabled={isLoading}
                                                        />
                                                    </div>
                                                    <small id='navbar-font-weight-help' className='form-text text-muted'>
                                                        Minimum ${tips_minimum_amount} USD.
                                                        <br />
                                                        {default_payment_method === 'crypto_currency' ?
                                                            <>
                                                                ${amount} will be debited from wallet balance.
                                                            </>
                                                            :
                                                            <>
                                                                ${amount} will be charged to the payment method you used previously on the website.
                                                            </>
                                                        }
                                                    </small>
                                                    <div className='form-group col p-0 mt-3 mb-0'>
                                                        <label htmlFor='message text-bold'>Personalized message:</label>
                                                        <AutoExpandingTextArea
                                                            className={classNames('form-control', {
                                                                [props.classes?.input_input]: props.classes
                                                            })}
                                                            onChange={(e) => setTipMessage(e.target.value)}
                                                            value={tipMessage}
                                                            readOnly={uploading}
                                                            maxLength={20000}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <button type='button' className='btn btn-block' onClick={onSendTip}
                                                        style={{ width: '100%', backgroundColor: content_color, color: content_font_color }}
                                                        disabled={isLoading}
                                                    >
                                                        Send a tip of ${amount}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </WebsiteFontColor>
            }
            {openDialog && messageType !== 'tip' &&
                <WebsiteFontColor>
                    <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg' role='document'>
                            <div className='modal-content' style={{ backgroundColor: card_background_color }}>
                                <div className='modal-header align-items-center'>
                                    <h5 className='modal-title'>Send Message</h5>
                                    <button className='close' onClick={handleClose} style={{ color: site_font_color, opacity: 1 }} disabled={uploading} />
                                </div>
                                <div className='modal-body'>
                                    <div className='container'>
                                        <div className='row justify-content-center'>
                                            <div className='text-center'>
                                                <div className='form-row'>
                                                    <div className='form-group col'>
                                                        <ChatInput
                                                            auth={props.auth}
                                                            value={props.message}
                                                            setValue={props.setMessage}
                                                            disabled={uploading}
                                                            usedInModal={true}
                                                            chat={props.chat}
                                                            clearChatTextInput={props.clearChatTextInput}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    isAdmin && <>
                                                        <span className='float-start mb-4 d-md-none'>Select Media from:</span>
                                                        <ButtonGroup
                                                            className='button-group d-flex mt-2 mb-4 flex-row'
                                                            content_color={content_color}
                                                            content_font_color={content_font_color}
                                                            button_shadow_color={button_shadow_color}
                                                        >
                                                            <StyledButton type='button'
                                                                className={classNames('btn mb-2 p-2 m-0 shadow-none w-100 rounded-start',
                                                                    {
                                                                        'border-2 selectedOption': selectFromContentManager,
                                                                        'shadow-none': !selectFromContentManager
                                                                    }
                                                                )}
                                                                disabled={uploading}
                                                                borderColor={!selectFromContentManager && content_color}
                                                                onClick={() => { setSelectFromContentManager(true) }}
                                                                backgroundColor={selectFromContentManager && content_color}
                                                                color={selectFromContentManager ? content_font_color : site_font_color}
                                                            >
                                                                <i className='d-block d-md-none fas fa-photo-video'></i>
                                                                <span className='d-none d-md-block'>CONTENT MANAGER</span>
                                                            </StyledButton>
                                                            <StyledButton type='button'
                                                                className={classNames('btn mb-2 p-2 m-0 shadow-none w-100 rounded-end',
                                                                    {
                                                                        'border-2 selectedOption': !selectFromContentManager,
                                                                        'shadow-none': selectFromContentManager
                                                                    }
                                                                )}
                                                                onClick={() => { setSelectFromContentManager(false) }}
                                                                disabled={uploading}
                                                                backgroundColor={!selectFromContentManager && content_color}
                                                                color={!selectFromContentManager ? content_font_color : site_font_color}
                                                                borderColor={selectFromContentManager && content_color}
                                                            >
                                                                <i className='d-block d-md-none fas fa-upload'></i>
                                                                <span className='d-none d-md-block'>UPLOAD NEW MEDIA</span>
                                                            </StyledButton>
                                                        </ButtonGroup>
                                                    </>
                                                }

                                                <PreviewContent
                                                    media={media}
                                                    contentManagerMedia={contentManagerMedia}
                                                    mediaPreview={mediaPreview}
                                                    contentMediaPreview={contentManagerMediaPreview}
                                                    isLoading={uploading}
                                                    handleDeletePhoto={handleDeletePhoto}
                                                    handleDrag={handleDrag}
                                                    handleDrop={handleDrop}
                                                    reArrangeImage={reArrangeImage}
                                                    uploadProgress={uploadProgress}
                                                    uploadProgressInMb={uploadProgressInMb}
                                                    handleGalleryChange={handleGalleryChange}
                                                    option={locked}
                                                    onChangePreviewMode={onChangePreviewMode}
                                                    isPreviewModeEnable={isPreviewModeEnable}
                                                    isPreviewModeEnableForMedia={contentManagerIsPreviewModeEnable}
                                                    galleryFilesFromPreview={galleryFilesFromPreview}
                                                    openDialog={onOpenDialog}
                                                    galleryFilesFromOriginal={galleryFilesFromOriginal}
                                                    isRearrangeModeEnable={isRearrangeModeEnable}
                                                    setIsRearrangeModeEnable={setIsRearrangeModeEnable}
                                                    isRearrangeModeEnableForMedia={isRearrangeModeEnableForMedia}
                                                    setIsRearrangeModeEnableForMedia={setIsRearrangeModeEnableForMedia}
                                                    showMarkAsPreviewButton={false}
                                                    isSelectedFromContentManager={selectFromContentManager}
                                                    setShowMediaSelectionPopup={setShowMediaSelectionPopup}
                                                    setShowMediaPreviewSelectionPopup={setShowMediaPreviewSelectionPopup}
                                                    requestFrom='message'
                                                    setSelectedCategory={setSelectedCategory}
                                                    selectedCategory={selectedCategory}
                                                />

                                                {isAdmin === true &&
                                                    <>
                                                        <ButtonGroup className='button-group d-flex mt-5' content_color={content_color} content_font_color={content_font_color} button_shadow_color={button_shadow_color}>
                                                            <button type='button'
                                                                className={classNames('btn col mb-2 p-2 m-0 rounded-start',
                                                                    {
                                                                        'border-2 selectedOption': locked === 'locked',
                                                                        'shadow-none': locked !== 'locked'
                                                                    }
                                                                )}
                                                                onClick={() => setLocked('locked')}
                                                                style={{
                                                                    borderRadius: '0% 0% 0% 0%',
                                                                    backgroundColor: locked === 'locked' && content_color,
                                                                    color: locked === 'locked' ? content_font_color : site_font_color,
                                                                    borderColor: locked !== 'locked' && content_color
                                                                }}
                                                                disabled={uploading}
                                                            >
                                                                Locked
                                                            </button>
                                                            <button type='button'
                                                                className={classNames('btn col mb-2 p-2 m-0 rounded-end',
                                                                    {
                                                                        'border-2 selectedOption': locked === 'free',
                                                                        'shadow-none': locked !== 'free'
                                                                    }
                                                                )}
                                                                onClick={() => {
                                                                    setLocked('free')
                                                                    setMediaPreview([])
                                                                    setIsPreviewModeEnable(false)
                                                                    setContentManagerMediaPreview([])
                                                                    setContentManagerIsPreviewModeEnable(false)
                                                                    setMediaPreviewId([])
                                                                }}
                                                                style={{
                                                                    borderRadius: '0% 0% 0% 0%',
                                                                    backgroundColor: locked === 'free' && content_color,
                                                                    color: locked === 'free' ? content_font_color : site_font_color,
                                                                    borderColor: locked !== 'free' && content_color
                                                                }}
                                                                disabled={uploading}
                                                            >
                                                                Free
                                                            </button>
                                                        </ButtonGroup>
                                                        {locked === 'locked' &&
                                                            <div className='form-group text-left'>
                                                                <label style={{ color: site_font_color }}>Amount</label>
                                                                <div className='input-group'>
                                                                    <div className='input-group-prepend'>
                                                                        <span className='input-group-text form-control'>$</span>
                                                                    </div>
                                                                    <input
                                                                        className='form-control col-md-6 col-12'
                                                                        type='number'
                                                                        name='amount'
                                                                        value={amount}
                                                                        onChange={(e) => setAmount(e.target.value)}
                                                                        min={5}
                                                                        max={450}
                                                                        disabled={uploading}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                <div className='row mt-5'>
                                                    <div className='col'>
                                                        <button
                                                            className='btn btn-block'
                                                            style={{
                                                                backgroundColor: content_color,
                                                                color: content_font_color,
                                                                boxShadow: `0 5px 15px 0 ${button_shadow_color}44, 0 4px 15px 0 ${button_shadow_color}44`
                                                            }}
                                                            onClick={submitHandler}
                                                            disabled={uploading}
                                                        >
                                                            {uploading === true && <span className="spinner-border spinner-border-sm mr-2"></span>} Send
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </WebsiteFontColor >
            }
            {
                isDialogOpen === true &&
                <FullScreenModelPopUpDialog
                    url={url}
                    handleClose={() => { onOpenDialog('', '') }}
                    type={type}
                    showWatermark={false}
                />
            }
            {
                (showMediaSelectionPopup || showMediaPreviewSelectionPopup) &&
                <MediaSelectionPopup
                    title={showMediaPreviewSelectionPopup ? 'Select Preview' : 'Select Media'}
                    onClose={() => { showMediaSelectionPopup ? setShowMediaSelectionPopup(false) : setShowMediaPreviewSelectionPopup(false) }}
                    prevSelectedMedia={showMediaSelectionPopup ? mediaIds : mediaPreviewId}
                    onSelection={(media) => { handleMedia(media, showMediaSelectionPopup ? 'original' : 'preview') }}
                />
            }
        </>
    )
}

ChatInputNewUI.propTypes = {
    auth: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    ccbill: PropTypes.object.isRequired,
    sendTip: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    chat: PropTypes.object.isRequired,
    onSend: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    addNewMessageInEnd: PropTypes.func.isRequired,
    payPerMessageCreditRemove: PropTypes.func.isRequired,
    payPerMessageCreditAdd: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    toggleChatTipPopup: PropTypes.func.isRequired,
    updateWalletAmount: PropTypes.func.isRequired,
    payPerMessageCryptoPayment: PropTypes.func,
    updateMediaCountInCategory: PropTypes.func.isRequired,
    inputType: PropTypes.string.isRequired,
    setInputType: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    updateUserList: PropTypes.string.isRequired,
    setMessage: PropTypes.string.isRequired,
    updateUsersLastMessage: PropTypes.string.isRequired,
    clearChatTextInput: PropTypes.func.isRequired,
    TipPayment: PropTypes.func.isRequired,
    tipUsingCrypto: PropTypes.func.isRequired,
    setShowAlertOnPageWrapper: PropTypes.func.isRequired,
    setConfirmSweetAlert: PropTypes.func.isRequired,
    unlockContentDetail: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    media: state.media,
    ccbill: state.ccbill,
    chat: state.chat
})

export default connect(
    mapStateToProps,
    {
        setSweetAlert,
        sendMessage,
        addNewMessageInEnd,
        toggleChatTipPopup,
        updateWalletAmount,
        updateMediaCountInCategory,
        updateUserList,
        updateUsersLastMessage,
        clearChatTextInput,
        TipPayment,
        setShowAlertOnPageWrapper,
        setConfirmSweetAlert,
        unlockContentDetail
    }
)(withRouter(ChatInputNewUI))

